import React, { Children } from "react";
import themes from "../../themes";
import styled from "styled-components";
import { fontWeight, devices } from "../../constants";

const TypoStyle = styled.p`
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  text-align: ${(props) => props.textAlign};
  margin: ${(props) => props.margin || 0};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => fontWeight[props.weight]};
  color: ${(props) => themes.colors[props.color] || themes.colors.white};
  line-height: ${(props) => props.lineHeight};
  letter-spacing: ${(props) => props.letterSpacing || "0px"};
  cursor: ${(props) => props.cursor};
  flex-grow: ${(props) => props.grow};
  text-wrap: ${(props) => props.wrap};
  word-wrap: ${(props) => props.wrap};
  word-break: ${(props) => props.break};
  padding: ${(props) => props.padding};
  border-bottom: ${(props) => props.borderBottom};
  :hover {
    color: ${(props) => themes.colors[props.hoverColor]};
  }

  &.link {
    text-decoration: ${(props) => props.decoration || "underline"};
  }

  &.gradient {
    background: ${(props) => themes.colors[props.color]};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  //ipad size
  @media only screen and (${devices.tablet}) {
    //width 648px + padding 20-20
    font-size: ${(props) => props.tabletSize};
  }
  //iphone size
  @media only screen and (${devices.mobile}) {
    //width 335px + padding 20-20
    font-size: ${(props) => props.mobileSize};
  }
`;

export const Typo = (props) => {
  const { children } = props;
  return <TypoStyle {...props}>{children}</TypoStyle>;
};

const H1 = (props) => {
  const { children } = props;
  return (
    <TypoStyle
      {...defaultProps.H1}
      {...props}
      tabletSize={defaultProps.H2.size}
      mobileSize={defaultProps.H3.size}
    >
      {children}
    </TypoStyle>
  );
};

const H2 = (props) => {
  const { children } = props;
  return (
    <TypoStyle
      {...defaultProps.H2}
      {...props}
      tabletSize={defaultProps.H3.size}
      mobileSize={defaultProps.H4.size}
    >
      {children}
    </TypoStyle>
  );
};

const H3 = (props) => {
  const { children } = props;
  return (
    <TypoStyle
      {...defaultProps.H3}
      {...props}
      tabletSize={defaultProps.H4.size}
      mobileSize={defaultProps.H5.size}
    >
      {children}
    </TypoStyle>
  );
};

const H4 = (props) => {
  const { children } = props;
  return (
    <TypoStyle
      {...defaultProps.H4}
      {...props}
      tabletSize={defaultProps.H5.size}
      mobileSize={defaultProps.H6.size}
    >
      {children}
    </TypoStyle>
  );
};

const H5 = (props) => {
  const { children } = props;
  return (
    <TypoStyle
      {...defaultProps.H5}
      {...props}
      tabletSize={defaultProps.H6.size}
      mobileSize={defaultProps.H7.size}
    >
      {children}
    </TypoStyle>
  );
};

const H6 = (props) => {
  const { children } = props;
  return (
    <TypoStyle
      {...defaultProps.H6}
      {...props}
      tabletSize={defaultProps.H7.size}
      mobileSize={defaultProps.Body1.size}
    >
      {children}
    </TypoStyle>
  );
};

const H7 = (props) => {
  const { children } = props;
  return (
    <TypoStyle
      {...defaultProps.H7}
      {...props}
      tabletSize={defaultProps.Body1.size}
      mobileSize={defaultProps.Body2.size}
    >
      {children}
    </TypoStyle>
  );
};

const Body1 = (props) => {
  const { children } = props;
  return (
    <TypoStyle
      {...defaultProps.Body1}
      {...props}
      tabletSize={defaultProps.Body2.size}
      mobileSize={defaultProps.Body3.size}
    >
      {children}
    </TypoStyle>
  );
};

const Body2 = (props) => {
  const { children } = props;
  return (
    <TypoStyle
      {...defaultProps.Body2}
      {...props}
      tabletSize={defaultProps.Body3.size}
      mobileSize={defaultProps.Body4.size}
    >
      {children}
    </TypoStyle>
  );
};

const Body3 = (props) => {
  const { children } = props;
  return (
    <TypoStyle {...defaultProps.Body3} {...props} {...props}>
      {children}
    </TypoStyle>
  );
};

const Body4 = (props) => {
  const { children } = props;
  return (
    <TypoStyle {...defaultProps.Body4} {...props}>
      {children}
    </TypoStyle>
  );
};

const Body5 = (props) => {
  const { children } = props;
  return (
    <TypoStyle {...defaultProps.Body5} {...props}>
      {children}
    </TypoStyle>
  );
};

const Body6 = (props) => {
  const { children } = props;
  return (
    <TypoStyle {...defaultProps.Body6} {...props}>
      {children}
    </TypoStyle>
  );
};

const Body7 = (props) => {
  const { children } = props;
  return (
    <TypoStyle {...defaultProps.Body7} {...props}>
      {children}
    </TypoStyle>
  );
};

const Body8 = (props) => {
  const { children } = props;
  return (
    <TypoStyle {...defaultProps.Body8} {...props}>
      {children}
    </TypoStyle>
  );
};

const Link1 = (props) => {
  const { children } = props;
  return (
    <TypoStyle className="link" {...defaultProps.Link1} {...props}>
      {children}
    </TypoStyle>
  );
};

const defaultProps = {
  H1: {
    size: "48px",
    weight: "bold",
    lineHeight: "60px",
  },
  H2: {
    size: "40px",
    weight: "bold",
    lineHeight: "60px",
  },
  H3: {
    size: "32px",
    weight: "bold",
    lineHeight: "40px",
  },
  H4: {
    size: "28px",
    weight: "semiBold",
    lineHeight: "36px",
  },
  H5: {
    size: "24px",
    weight: "semiBold",
    lineHeight: "32px",
  },
  H6: {
    size: "22px",
    weight: "semiBold",
    lineHeight: "28px",
  },
  H7: {
    size: "20px",
    weight: "semiBold",
    lineHeight: "26px",
  },
  Body1: {
    size: "18px",
    weight: "regular",
    lineHeight: "26px",
  },
  Body2: {
    size: "18px",
    weight: "semiBold",
    lineHeight: "26px",
  },
  Body3: {
    size: "16px",
    weight: "regular",
    lineHeight: "20px",
  },
  Body4: {
    size: "16px",
    weight: "semiBold",
    lineHeight: "20px",
  },
  Body5: {
    size: "14px",
    weight: "regular",
    lineHeight: "18px",
  },
  Body6: {
    size: "14px",
    weight: "semiBold",
    lineHeight: "18px",
  },
  Body7: {
    size: "12px",
    weight: "regular",
    lineHeight: "16px",
  },
  Body8: {
    size: "12px",
    weight: "semiBold",
    lineHeight: "16px",
  },
  Link1: {
    size: "14px",
    weight: "semiBold",
    lineHeight: "18px",
  },
};

Typo.H1 = H1;
Typo.H2 = H2;
Typo.H3 = H3;
Typo.H4 = H4;
Typo.H5 = H5;
Typo.H6 = H6;
Typo.H7 = H7;
Typo.Body1 = Body1;
Typo.Body2 = Body2;
Typo.Body3 = Body3;
Typo.Body4 = Body4;
Typo.Body5 = Body5;
Typo.Body6 = Body6;
Typo.Body7 = Body7;
Typo.Body8 = Body8;
Typo.Link1 = Link1;
