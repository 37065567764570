import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  ContentContainer,
  Typo,
  Flex,
  Line,
  Grid,
  Button,
  Input,
  Div,
} from "../../components";
import Context from "../../Context";
import { useParams } from "react-router-dom";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import themes from "../../themes";
import { Collapse } from "reactstrap";
import { validate } from "../../constants";
import { resizeImage, monthTh } from "../../util";

export const PointRedeem = () => {
  const { callApi, configs, profileState, go, toast } = Context();
  const uploadRef = useRef(null);
  const { productId } = useParams();
  const [coupon, setCoupon] = useState();
  const [isShowForm, setShowForm] = useState(false);
  const [previewImage, setPreviewImage] = useState();
  const [buyer, setBuyer] = useState({
    name: "",
    address: "",
    phone: "",
    note: "",
    image: null,
  });

  useEffect(() => {
    (async () => {
      const coupon = await callApi("products-getProduct", {
        id: productId,
      });

      if (!coupon.data.status) {
        toast("ไม่พบสินค้าที่เลือก");
        go("/point/products");
        return;
      }

      if (coupon.data.quantity < 1) {
        toast("สินค้าหมดชั่วคราว");
        go("/point/products");
        return;
      }
      if (coupon.data.status !== "active") {
        toast("คูปองนี้ปิดใช้งานชั่วคราว");
        go("/point/products");
        return;
      }

      setCoupon(coupon.data);
    })();
  }, [productId]);

  const handleSubmit = async () => {
    if (Number(profileState.buyPoint) < Number(coupon.point)) {
      if (!buyer.image) {
        return toast("กรุณาอัปโหลดสลิป");
      }
    }
    const { orderId } = await callApi("products-createRedeemTransaction", {
      ...buyer,
      id: productId,
    });
    if (!buyer.image && orderId) {
      go(`/status/point_redeemed/${orderId}`);
    } else if (buyer.image) {
      go(`/status/point_redeemed`);
    } else {
      go("/status/canceled");
    }
  };

  return (
    <Container margin="0 0 75px 0">
      <Flex align="center" wrap="wrap" width="100%" margin="25px 0 25px 0">
        <Typo.Link1
          onClick={() => go("/")}
          color="navy"
          cursor="pointer"
          decoration="none"
        >
          หน้าแรก
        </Typo.Link1>
        <Typo.Body6 color="navy" margin="0 7px">
          <IoIosArrowForward />
        </Typo.Body6>
        <Typo.Link1
          onClick={() => go("/point/products")}
          color="navy"
          cursor="pointer"
          decoration="none"
        >
          แลกคะแนน
        </Typo.Link1>
        <Typo.Body6 color="textBlack" margin="0 7px">
          <IoIosArrowForward />
        </Typo.Body6>
        <Typo.Body6 color="gray5">{coupon?.name || "-"}</Typo.Body6>
      </Flex>
      <Typo.H6 color="navy" textAlign="center">
        แลกคะแนน
      </Typo.H6>

      <ContentContainer
        bgcolor="gradient2"
        minHeight="10px"
        padding="16px 24px"
      >
        <Flex justify="space-between" align="center">
          <Flex direction="column" align="center" gap="8px">
            <Typo.Body2 color="white">คะแนนสะสมของคุณ</Typo.Body2>
            <Typo.Body5>
              ใช้ได้ถึงสิ้นเดือน {monthTh[profileState.pointExpireMonth || 12]}
              {configs.roundDate.split("-")[1] > profileState.pointExpireMonth
                ? " 2568"
                : " 2567"}
            </Typo.Body5>
          </Flex>
          <Flex align="center" gap="10px">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.1593 0.443922C2.8527 1.80168 -3.27818 14.4552 2.65518 24.1873C6.88822 31.1305 15.9265 33.6553 23.16 29.9151C32.5898 25.0394 34.5623 12.4299 27.0676 4.9352C23.71 1.5776 18.8373 -0.117838 14.1593 0.443922ZM17.1762 7.87264C23.7081 8.62368 24.4731 17.6089 18.1593 19.4201C17.6254 19.5732 17.5036 19.5828 15.7686 19.6087L13.9405 19.636V21.9116V24.1873H11.7217H9.50302V15.9998V7.81224L13.0812 7.81272C15.0491 7.81296 16.8919 7.83992 17.1762 7.87264ZM13.9405 13.7112V15.7498H15.1258C17.1836 15.7498 17.9047 15.2222 17.9023 13.7185C17.9 12.2583 17.2163 11.7531 15.1749 11.703L13.9405 11.6726V13.7112Z"
                fill="#FFCD00"
              />
            </svg>
            <Typo.H2 color="yellow" margin="5px 0 0 0">
              {new Intl.NumberFormat().format(profileState.buyPoint || 0)}
            </Typo.H2>
          </Flex>
        </Flex>
      </ContentContainer>

      <ContentContainer
        padding="32px 80px"
        paddingMobile="24px 20px"
        minHeight="0"
      >
        <Typo.H7 color="textBlack">รายการสินค้า</Typo.H7>
        <Flex margin="24px 0" align="center" gap="8px">
          <img src="/images/logo_single.png" alt="logo_single" width="40px" />
          <Typo.Body1 color="textBlack">หงษ์ทอง ลอตเตอรี่ออนไลน์</Typo.Body1>
        </Flex>
        <Flex
          padding="14px 16px"
          radius="12px"
          border={`1px solid ${themes.colors.gray2}`}
          justify="space-between"
          align="center"
          wrap="wrap"
        >
          <Flex>
            <Typo.Body3 color="textBlack"> {coupon?.name || "-"}</Typo.Body3>
          </Flex>

          <Typo.Body3 lineHeight="15px" margin="0 10px 0 0" color={"textBlack"}>
            {coupon?.point || 0} คะแนน
          </Typo.Body3>
        </Flex>
      </ContentContainer>

      <ContentContainer
        padding="32px 80px"
        paddingMobile="24px 20px"
        minHeight="0"
      >
        <Typo.H7 color="textBlack">สรุปรายการทั้งหมด</Typo.H7>
        <Typo.Body2 color="navy" margin="15px 0 0 0">
          ค่าสินค้า
        </Typo.Body2>
        <Flex align="center" justify="space-between">
          <Flex align="center" wrap="wrap">
            <Typo.Body1 color="textBlack"> {coupon?.name || "-"}</Typo.Body1>
            <Typo.Body1 color="gray4">
              : รหัสสินค้า {productId.slice(-5).toUpperCase()}
            </Typo.Body1>
          </Flex>
          <Typo.Body3 lineHeight="15px" margin="0 10px 0 0" color="textBlack">
            {coupon?.point.toLocaleString()} คะแนน
          </Typo.Body3>
        </Flex>

        <Typo.Body2 color="navy" margin="15px 0 0 0">
          สรุปคะแนน
        </Typo.Body2>
        <Flex align="center" justify="space-between">
          <Typo.Body1 color="textBlack"> คะแนนสะสมที่คุณมี </Typo.Body1>
          <Typo.Body3 lineHeight="15px" margin="0 10px 0 0" color="textBlack">
            {profileState?.buyPoint} คะแนน
          </Typo.Body3>
        </Flex>
        <Flex align="center" justify="space-between">
          <Typo.Body1 color="textBlack">คะแนนสะสมหลังหักค่าสินค้า</Typo.Body1>
          <Typo.Body3 lineHeight="15px" margin="0 10px 0 0" color="textBlack">
            {profileState?.buyPoint - coupon?.point < 1
              ? 0
              : profileState?.buyPoint - coupon?.point}{" "}
            คะแนน
          </Typo.Body3>
        </Flex>

        <Line border={`2px solid ${themes.colors.gray2}`} />
        <Flex align="center" justify="space-between">
          <Typo.Body1 color="textBlack">
            จำนวนเงินที่ต้องชำระเพิ่มเติม
          </Typo.Body1>

          <Typo.H5 lineHeight="15px" margin="0 10px 0 0" color="red">
            {Number(coupon?.point) - Number(profileState.buyPoint) < 0
              ? 0
              : (
                  Number(coupon?.point) - Number(profileState.buyPoint)
                ).toLocaleString()}{" "}
            บาท
          </Typo.H5>
        </Flex>
      </ContentContainer>
      <Collapse isOpen={isShowForm}>
        <ContentContainer
          minHeight="10px"
          padding="32px 80px"
          paddingMobile="24px 20px"
        >
          <Flex align="center">
            <Typo.Body1 width="100px" color="gray5">
              ธนาคาร
            </Typo.Body1>
            <img src="/images/SCB.png" alt="logo" width={86} />
            <Typo.Body1 margin="0 0 0 10px" color="textBlack">
              ธนาคารไทยพาณิชย์ จำกัด (มหาชน)
            </Typo.Body1>
          </Flex>
          <Flex align="center" margin="20px 0">
            <Typo.Body1 width="100px" color="gray5">
              ชื่อบัญชี
            </Typo.Body1>

            <Typo.Body1 color="textBlack">
              บริษัท หงษ์ทอง ออนไลน์ จำกัด
            </Typo.Body1>
          </Flex>
          <Flex align="center" justify="space-between">
            <Flex>
              <Typo.Body1 width="100px" color="gray5">
                เลขที่บัญชี
              </Typo.Body1>
              <Typo.Body1 id="btnCopyLink" color="textBlack">
                421-0-96364-4
              </Typo.Body1>
            </Flex>

            <Button
              background="yellow"
              hoverBg="transparent"
              hoverColor="yellow"
              hoverBorder="yellow"
              color="navy"
              onClick={() => {
                const copyText =
                  document.getElementById("btnCopyLink").innerHTML;
                window.navigator.clipboard.writeText(copyText);
                // toast("คัดลอกเลขบัญชีเเล้ว", {
                //   icon: "success",
                //   autoClose: 1000,
                // });
              }}
            >
              คัดลอก
            </Button>
          </Flex>
          <Line />
          <Flex align="center" justify="space-between">
            <Typo.Body1 id="btnCopyLink" color="textBlack">
              ยอดที่ต้องชำระ
            </Typo.Body1>

            <Typo.H5 color="red">
              {Number(coupon?.point) - Number(profileState.buyPoint) < 0
                ? 0
                : (
                    Number(coupon?.point) - Number(profileState.buyPoint)
                  ).toLocaleString()}{" "}
              บาท
            </Typo.H5>
          </Flex>
          {previewImage && (
            <>
              <Line />
              <Flex align="center" justify="space-between">
                <Typo.Body1 id="btnCopyLink" color="textBlack">
                  หลักฐานการโอนเงิน
                </Typo.Body1>
                <Flex gap="15px">
                  <Button
                    background="navy"
                    hoverBg="transparent"
                    hoverBorder="navy"
                    hoverColor="navy"
                    onClick={() => window.open(previewImage)}
                  >
                    ดูสลิป
                  </Button>
                  <Button
                    background="red"
                    hoverBg="transparent"
                    hoverBorder="red"
                    hoverColor="red"
                    onClick={() => {
                      setPreviewImage(undefined);
                      setBuyer((prevState) => ({
                        ...prevState,
                        image: false,
                      }));
                    }}
                  >
                    ลบ
                  </Button>
                </Flex>
              </Flex>
            </>
          )}
        </ContentContainer>
        {!previewImage && (
          <>
            <Button
              margin="24px 0 12px 0"
              height="55px"
              width="100%"
              hoverColor="navy"
              background="gradient2"
              hoverBg="transparent"
              hoverBorder="navy"
              gap="10px"
              onClick={() => uploadRef.current.click()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.147 0.882191C9.965 0.960341 4.86025 6.05364 4.7475 6.26964C4.4763 6.78904 4.83795 7.45039 5.41615 7.49219C5.85085 7.52364 5.7787 7.58189 7.7935 5.57244L9.63925 3.73169V9.00939C9.63925 13.6601 9.64635 14.3074 9.69935 14.4575C9.9632 15.2053 11.0856 15.1773 11.3134 14.4172C11.3458 14.3089 11.358 12.8345 11.358 9.00004V3.73169L13.2037 5.57244C15.2185 7.58189 15.1463 7.52364 15.581 7.49219C16.1598 7.45034 16.5193 6.78919 16.2476 6.26644C16.1304 6.04094 11.0284 0.957941 10.8416 0.880591C10.6587 0.804791 10.3254 0.805541 10.147 0.882191ZM1.8072 10.8944C1.61575 10.9831 1.46005 11.1441 1.3796 11.3367C1.29215 11.5461 1.2893 15.3677 1.37625 15.8641C1.6684 17.5322 2.9665 18.8303 4.6346 19.1224C5.1241 19.2082 15.8731 19.2082 16.3626 19.1224C18.0307 18.8303 19.3288 17.5322 19.6209 15.8641C19.7079 15.3677 19.705 11.5461 19.6176 11.3367C19.3649 10.7318 18.5035 10.6408 18.1299 11.1796L18.0181 11.3409L17.9944 13.4341C17.9689 15.68 17.9692 15.6765 17.7544 16.1392C17.4628 16.7674 16.8669 17.2494 16.1626 17.4265C15.8434 17.5068 5.15375 17.5068 4.83455 17.4265C4.1303 17.2494 3.5344 16.7674 3.24275 16.1392C3.028 15.6765 3.0283 15.68 3.0028 13.4341L2.97905 11.3409L2.86725 11.1796C2.64565 10.86 2.16235 10.73 1.8072 10.8944Z"
                  fill="#FFCD00"
                />
              </svg>
              อัปโหลดสลิป
            </Button>
            <input
              ref={uploadRef}
              style={{ display: "none" }}
              type="file"
              onChange={async (e) => {
                const file = e.target.files[0];
                const base64 = await resizeImage(file);
                if (base64) {
                  const previewUrl = URL.createObjectURL(file);
                  setPreviewImage(previewUrl);
                  setBuyer((prevState) => ({
                    ...prevState,
                    image: base64,
                  }));
                } else {
                  alert("อัพโหลด ได้เฉพาะ ไฟล์รูปภาพ นามสกุล jpg หรือ png");
                }
              }}
            />

            <Typo.Body5 color="gray5" textAlign="center">
              รองรับนามสกุลไฟล์ .jpg, .png, .jpeg เท่านั้น
            </Typo.Body5>
          </>
        )}
      </Collapse>

      <Collapse
        isOpen={
          previewImage || Number(profileState.buyPoint) >= Number(coupon?.point)
        }
      >
        <ContentContainer
          padding="34px 55px"
          paddingMobile="16px"
          margin="24px 0 0 0"
          minHeight="0px"
        >
          <Typo.Body2 margin="0 0 28px 0" color="navy">
            ข้อมูลผู้ซื้อ
          </Typo.Body2>
          <Grid
            columns={2}
            justify="flex-start"
            columnsIpad={1}
            columnsMobile={1}
            gap="20px"
          >
            <Div width="100%">
              <Typo.Body5 color="gray4">ชื่อ - นามสกุล</Typo.Body5>

              <Input
                placeholder="ชื่อ"
                value={buyer.name}
                type="input"
                onChange={(e) => {
                  setBuyer((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }));
                }}
              />
            </Div>

            <Div width="100%">
              <Typo.Body5 color="gray4">เบอร์โทรศัพท์</Typo.Body5>
              <Input
                placeHolder="เบอร์โทรศัพท์"
                value={buyer.phone}
                type="input"
                onChange={(e) => {
                  if (validate.digitOnly.test(e.target.value)) {
                    setBuyer((prevState) => ({
                      ...prevState,
                      phone: e.target.value,
                    }));
                  }
                }}
              />
            </Div>
            {!coupon?.hide?.address && (
              <Div width="100%">
                <Typo.Body5 color="gray4">ที่อยู่</Typo.Body5>

                <Input
                  placeholder="ที่อยู่"
                  value={buyer.address}
                  type="input"
                  onChange={(e) => {
                    setBuyer((prevState) => ({
                      ...prevState,
                      address: e.target.value,
                    }));
                  }}
                />
              </Div>
            )}
            <Div width="100%">
              <Typo.Body5 color="gray4">
                หมายเหตุเพิ่มเติม (ไซส์ สี ขนาด ถ้ามี)
              </Typo.Body5>
              <Input
                placeholder="หมายเหตุเพิ่มเติม"
                value={buyer.note}
                type="input"
                onChange={(e) => {
                  setBuyer((prevState) => ({
                    ...prevState,
                    note: e.target.value,
                  }));
                }}
              />
            </Div>
          </Grid>
        </ContentContainer>
      </Collapse>
      {Number(profileState.buyPoint) >= Number(coupon?.point) ? (
        <Button
          disabled={
            coupon.hide?.address
              ? buyer.name === "" || buyer.phone === ""
              : buyer.name === "" || buyer.phone === "" || buyer.address === ""
          }
          width="100%"
          hoverBg="transparent"
          hoverBorder="navy"
          hoverColor="navy"
          background="gradient2"
          onClick={() => handleSubmit()}
          margin="24px 0"
        >
          ยืนยันคำสั่งซื้อ <IoIosArrowForward style={{}} />
        </Button>
      ) : isShowForm ? (
        <Button
          disabled={
            buyer.name === "" || buyer.phone === "" || buyer.address === ""
          }
          width="100%"
          hoverBg="transparent"
          hoverBorder="navy"
          hoverColor="navy"
          background="gradient2"
          onClick={() => handleSubmit()}
          margin="24px 0"
        >
          ยืนยันคำสั่งซื้อ <IoIosArrowForward style={{}} />
        </Button>
      ) : (
        <Grid margin="24px 0" columns={2}>
          <Button
            color="navy"
            onClick={() => go("/point/products")}
            hoverBg="transparent"
            background="transparent"
          >
            <IoIosArrowBack
              style={{
                color: themes.colors.navy,
                marginRight: "7px",
              }}
            />
            ย้อนกลับ
          </Button>
          <Button
            width="100%"
            hoverBg="transparent"
            hoverBorder="navy"
            hoverColor="navy"
            background="gradient2"
            onClick={() => setShowForm(true)}
          >
            ดำเนินการต่อ <IoIosArrowForward style={{}} />
          </Button>
        </Grid>
      )}
    </Container>
  );
};
