import React, { useEffect, useState } from "react";
import Context from "../../Context";

import { Container, Typo, Flex, CouponContainer } from "../../components";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

export const CouponHistory = () => {
  const { callApi, configs, profileState, go } = Context();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    (async () => {
      await callApi("coupons-validateExpireCouponByUserId");
      const result = await callApi("coupons-getTransactionByUserId", {
        isPointRedeem: false,
      });

      setOrders(result.data);
    })();
  }, []);

  return (
    <Container>
      <Flex align="center" wrap="wrap" width="100%" margin="0 0 25px 0">
        <Typo.Link1
          onClick={() => go("/")}
          color="navy"
          cursor="pointer"
          decoration="none"
        >
          หน้าแรก
        </Typo.Link1>
        <Typo.Body6 color="textBlack" margin="0 7px">
          <IoIosArrowForward />
        </Typo.Body6>
        <Typo.Link1
          onClick={() => go("/coupon")}
          color="navy"
          cursor="pointer"
          decoration="none"
        >
          คูปอง
        </Typo.Link1>
        <Typo.Body6 color="textBlack" margin="0 7px">
          <IoIosArrowForward />
        </Typo.Body6>
        <Typo.Body6 color="gray5">ประวัติการสั่งซื้อ</Typo.Body6>
      </Flex>
      <Typo.H6 color="navy" textAlign="center">
        ประวัติการสั่งซื้อ
      </Typo.H6>

      <Flex margin="25px 0" justify="space-between" wrap="wrap">
        <Typo.Link1 color="navy" cursor="pointer" onClick={() => go("/coupon")}>
          <IoIosArrowBack /> คูปอง
        </Typo.Link1>
        <Typo.Body2 color="textBlack">
          การสั่งซื้อ {orders?.length || 0} ครั้ง
        </Typo.Body2>
      </Flex>

      {orders
        .sort((a, b) => b.createdAt - a.createdAt)
        .map((order) => {
          return <CouponContainer order={order} product={order.product} />;
        })}
    </Container>
  );
};
