import React from "react";
import style from "../../constants/style";
import styled from "styled-components";
import themes from "../../themes";
import { devices } from "../../constants";
const GridStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => props.columns || 3},
    minmax(0, 1fr)
  );
  justify-items: ${(props) => props.justify || "center"};
  align-items: ${(props) => props.align || "center"};
  gap: ${(props) => props.gap};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  width: ${(props) => props.width};
  background: ${(props) => themes.colors[props.background]};
  border-radius: ${(props) => props.radius};
  overflow: ${(props) => props.overflow};
  &.fix-height {
    grid-auto-columns: 1fr;
  }
  //ipad size
  @media only screen and (${devices.tablet}) {
    grid-template-columns: repeat(
      ${(props) => props.columnsTablet || 2},
      minmax(0, 1fr)
    );
    align-items: ${(props) => props.alignTablet || props.align};
    gap: ${(props) => props.gapTablet};
  }

  @media only screen and (max-width: 640px) {
    grid-template-columns: repeat(
      ${(props) => props.columnsIpad || 2},
      minmax(0, 1fr)
    );

    align-items: ${(props) => props.alignIpad || props.align};
  }
  //iphone size
  @media only screen and (${devices.mobile}) {
    grid-template-columns: repeat(
      ${(props) => props.columnsMobile || 2},
      minmax(0, 1fr)
    );
    align-items: ${(props) => props.alignMobile || props.align};
    justify-items: ${(props) => props.justifyMobile || "center"};
    gap: ${(props) => props.gapMobile};
  }
`;
export const Grid = (props) => {
  const { children, className } = props;
  return (
    <GridStyle className={className} {...props}>
      {children}
    </GridStyle>
  );
};
