import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import style from "../constants/style";
import Context from "../Context";
import { provinces, bankNames } from "../constants";
import constant from "../constants";
import {
  TextBody,
  WhiteHolder,
  FlexHolder,
  Flex,
  Label,
  Typo,
  Container,
  ContentContainer,
  Button,
  Grid,
} from "../components";
import { LineButton } from "../components/Btn";
import themes from "../themes";
import { FiEdit, FiSave, FiCopy } from "react-icons/fi";
import { FaQuestionCircle } from "react-icons/fa";
import { isEmpty, isNil } from "ramda";
import {
  Collapse,
  Modal,
  ModalBody,
  FormGroup,
  Input as ReactstrapInput,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { bankList } from "../constants";
export const Profile = () => {
  const { callApi, profileState, setProfileState, toast, go, configs } =
    Context();

  useEffect(() => {
    (async () => {
      const code = new URLSearchParams(window.location.search).get("code");
      if (code) {
        localStorage.removeItem("accessToken");
        const lineLoginResult = await callApi("users/lineLogin", { code });

        if (lineLoginResult.data.accessToken) {
          localStorage.setItem("accessToken", lineLoginResult.data.accessToken);
          setProfileState(lineLoginResult.data.user);
          if (localStorage.getItem("redirect")) {
            const redirect = localStorage.getItem("redirect");
            localStorage.removeItem("redirect");
            go(redirect);
          }
        }
      }
    })();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setDetail(getDetail(profileState));
  }, [profileState]);
  const getDetail = ({ firstName, lastName, phone, email }) => {
    return [
      {
        label: "ชื่อ",
        placeHolder: "ระบุชื่อจริงของท่าน",
        value: firstName,
        key: "firstName",
        type: "input",
      },
      {
        label: "นามสกุล",
        placeHolder: "ระบุนามสกุลของท่าน",
        value: lastName,
        key: "lastName",
        type: "input",
      },
      {
        label: "เบอร์โทรศัพท์",
        placeHolder: "ระบุเบอร์โทรศัพท์ของท่าน",
        value: phone,
        key: "phone",
        type: "input",
        readOnly: true,
      },
      {
        label: "อีเมล",
        placeHolder: "ระบุเบอร์อีเมลของท่าน",
        value: email,
        key: "email",
        type: "input",
      },
    ];
  };
  const getLink = ({ userNo, userId }) => {
    const link = constant.webConfigs.customerUrl + "HT/" + userId;
    const linkAffiliate = constant.webConfigs.officialUrl + userNo;
    return [
      {
        value: link,
        key: "userNo",
        type: "input",
        outSideGrid: true,
        handleClick: ({ ref }) => {
          const elt = ref.current;
          elt.focus();
          elt.select();
          elt.setSelectionRange(0, 99999);
          window.navigator.clipboard.writeText(link);
          elt.select();
          toast("คัดลอก: " + link, { autoClose: 1000, icon: "success" });
        },
      },
      {
        value: linkAffiliate,
        key: "userNo",
        type: "input",
        outSideGrid: true,
        handleClick: ({ ref }) => {
          const elt = ref.current;
          elt.focus();
          elt.select();
          elt.setSelectionRange(0, 99999);
          window.navigator.clipboard.writeText(linkAffiliate);
          elt.select();
          toast("คัดลอก: " + linkAffiliate, {
            autoClose: 1000,
            icon: "success",
          });
        },
      },
    ];
  };
  const resizeImage = (file) =>
    new Promise((resolve, reject) => {
      if (!["image/jpeg", "image/jpg", "image/png"].includes(file.type))
        reject(false);
      const maxW = 600; //กำหนดความกว้าง
      const maxH = 1200; //กำหนดความสูง
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      const img = document.createElement("img");
      img.onload = function () {
        const iw = img.width;
        const ih = img.height;
        const scale = Math.min(maxW / iw, maxH / ih);
        const iwScaled = iw * scale;
        const ihScaled = ih * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        context.drawImage(img, 0, 0, iwScaled, ihScaled);
        resolve(canvas.toDataURL("image/jpeg", 1)); //0.5 คือ คุณภาพของรูป ที่ Resize
      };
      img.src = URL.createObjectURL(file);
    });

  const getAccount = ({ bank }) => {
    return [
      {
        label: "ชื่อบัญชี",
        value: bank?.accName,
        key: "bank.accName",
        type: "input",
      },
      {
        label: "ชื่อธนาคาร",
        value: bank?.name,
        choices: bankList,
        key: "bank.name",
        type: "input",
      },
      {
        label: "เลขที่บัญชี",
        value: bank?.accNo,
        key: "bank.accNo",
        type: "input",
      },
      {
        key: "bank.currency",
        value: bank?.currency,
      },
    ];
  };
  const getAddress = ({ province, addr, zipcode }) => {
    return [
      {
        label: "จังหวัด",
        value: province,
        key: "address.province",
        type: "options",
        choices: provinces,
      },
      {
        label: "ที่อยู่ในการจัดส่ง",
        value: addr,
        key: "address.addr",
        type: "textarea",
        outSideGrid: true,
      },
      {
        label: "รหัสไปรษณีย์",
        value: zipcode,
        key: "address.zipcode",
        type: "input",
      },
    ];
  };

  const [detail, setDetail] = useState(getDetail(profileState));
  const [isEditingDetail, setIsEditingDetail] = useState(false);
  const [link, setLink] = useState(getLink(profileState));
  const [account, setAccount] = useState(getAccount(profileState));
  const [isEditingAccount, setIsEditingAccount] = useState(false);
  const [address, setAddress] = useState(getAddress(profileState.address));
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [noti, setNoti] = useState([
    {
      key: "notifyType",
      value: profileState.lineId ? profileState.notifyType : "SMS",
    },
  ]);
  const [isEditingNoti, setIsEditingNoti] = useState(false);
  const [isOpenApp, setIsOpenApp] = useState(false);
  const [isOpenBuy, setIsOpenBuy] = useState(false);
  const [modalWithdraw, setModalWithdraw] = useState(false);
  const [withdraw, setWithdraw] = useState(0);
  const save = async (inputs) => {
    await callApi("users-editSelf", {
      fields: inputs || [],
      id: profileState.userId,
    });

    // window.location.reload();
  };

  const withdrawCash = async (inputs) => {
    await callApi("cashes-createWithdraw", {
      withdraw,
    });
  };
  const toggle = () => setModalWithdraw(!modalWithdraw);
  return (
    <Flex direction="column" justify="flex-start" align="center" width="100%">
      <Container margin="0 0 25px 0">
        <ContentContainer
          minHeight="0"
          bgcolor="newGrReverse"
          padding="60px 16px 16px 16px"
          paddingTablet="60px 16px 16px 16px"
          paddingMobile="60px 8px 16px 8px"
          margin="80px 0 0 0"
        >
          <label htmlFor="files-slips">
            <Avatar
              src={
                profileState.profileImage
                  ? profileState.profileImage
                  : "/images/icon_profile.png"
              }
              alt="profile_logo"
              htmlFor="files-slips"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "45px",
                  height: "45px",
                  borderRadius: "50%",
                  backgroundColor: themes.colors.yellow,
                  color: themes.colors.navy,
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                }}
                htmlFor="files-slips"
              >
                <FiEdit style={{ fontSize: "1.5em" }} />
              </div>
            </Avatar>
          </label>

          <input
            style={{
              marginBottom: "15px",
              visibility: "hidden",
              position: "absolute",
              display: "none",
            }}
            id="files-slips"
            type="file"
            onChange={async (e) => {
              const file = e.target.files[0];
              const base64 = await resizeImage(file);
              if (base64) {
                await callApi("users-updateProfileImage", { base64 });
                window.location.reload();
              } else {
                alert("อัพโหลด ได้เฉพาะ ไฟล์รูปภาพ นามสกุล jpg หรือ png");
              }
            }}
          />
          <Typo.H6 textAlign="center" lineHeight="28px">
            {profileState.firstName} {profileState.lastName}
          </Typo.H6>
          <Typo.Body5 textAlign="center">
            เลขสมาชิก: HT{profileState.userId || "xxxxxx"}
          </Typo.Body5>
        </ContentContainer>

        {/* ////////ข้อมูลส่วนตัว */}
        <ContentContainer
          margin="32px 0 0 0"
          minHeight="0"
          bgcolor="newGrReverse"
          padding="10px 55px"
        >
          <Flex align="center" justify="space-between">
            <Typo.Body2>ข้อมูลส่วนตัว</Typo.Body2>
            {/* ////disabled change info */}
            {/* <Button
                background={isEditingDetail ? "primary" : "white"}
                color="navy"
                padding="0px 8px"
                radius="7px"
                onClick={() =>
                  setIsEditingDetail((isEditingDetail) => {
                    const isSomeEmpty = detail.some((data) => !data.value);
                    if (isEditingDetail && isSomeEmpty) {
                      toast("กรุณากรอกข้อมูลให้ครบทุกช่อง");
                      return isEditingDetail;
                    }
                    isEditingDetail && save(detail);
                    return !isEditingDetail;
                  })
                }
              >
                {isEditingDetail ? "บันทึก" : "แก้ไข"}
              </Button> */}
          </Flex>
        </ContentContainer>
        <ContentContainer margin="10px 0 0 0" minHeight="0" padding="10px 55px">
          <Typo.Body5 color="gray4" margin="5px 0 0 0">
            ชื่อ - นามสกุล
          </Typo.Body5>
          {isEditingDetail ? (
            <Flex>
              <Input
                isEditing={isEditingDetail}
                placeHolder="ระบุชื่อจริงของท่าน"
                value={profileState.firstName}
                key="firstName"
                type="input"
                setValue={(newValue) =>
                  setDetail((x) => {
                    x[0].value = newValue;
                    return x;
                  })
                }
              />
              <Input
                isEditing={isEditingDetail}
                placeHolder="ระบุนามสกุลของท่าน"
                value={profileState.lastName}
                key="lastName"
                type="input"
                setValue={(newValue) =>
                  setDetail((x) => {
                    x[1].value = newValue;
                    return x;
                  })
                }
              />
            </Flex>
          ) : (
            <Typo.Body1 margin="3px 0 0 0" color="textBlack">
              {profileState.firstName} {profileState.lastName}
            </Typo.Body1>
          )}
        </ContentContainer>
        <ContentContainer margin="10px 0 0 0" minHeight="0" padding="10px 55px">
          <Typo.Body5 color="gray4" margin="5px 0 0 0">
            สัญชาติ
          </Typo.Body5>
          <Typo.Body1 margin="3px 0 0 0" color="textBlack">
            {profileState.nationality === ""
              ? "-"
              : profileState.nationality === "TH"
              ? "ไทย"
              : profileState.nationality === "LAO"
              ? "ลาว"
              : "-"}
          </Typo.Body1>
        </ContentContainer>
        <ContentContainer margin="10px 0 0 0" minHeight="0" padding="10px 55px">
          <Typo.Body5 color="gray4" margin="5px 0 0 0">
            เบอร์โทรศัพท์
          </Typo.Body5>
          {isEditingDetail ? (
            <Input
              isEditing={isEditingDetail}
              placeHolder="ระบุเบอร์โทรศัพท์ของท่าน"
              value={profileState.phone}
              key="phone"
              type="input"
              setValue={(newValue) =>
                setDetail((x) => {
                  x[2].value = newValue;
                  return x;
                })
              }
            />
          ) : (
            <Typo.Body1 margin="3px 0 0 0" color="textBlack">
              {profileState.phone}
            </Typo.Body1>
          )}
        </ContentContainer>
        <ContentContainer margin="10px 0 0 0" minHeight="0" padding="10px 55px">
          <Typo.Body5 color="gray4" margin="5px 0 0 0">
            อีเมล
          </Typo.Body5>
          {isEditingDetail ? (
            <Input
              isEditing={isEditingDetail}
              placeHolder="ระบุเบอร์อีเมลของท่าน"
              value={profileState.email}
              key="email"
              type="input"
              setValue={(newValue) =>
                setDetail((x) => {
                  x[3].value = newValue;
                  return x;
                })
              }
            />
          ) : (
            <Typo.Body1 margin="3px 0 0 0" color="textBlack">
              {profileState.email || "-"}
            </Typo.Body1>
          )}
        </ContentContainer>

        {/* //////////////ชวนเพื่อน */}
        <ContentContainer
          margin="32px 0 0 0"
          minHeight="0"
          bgcolor="newGrReverse"
          padding="10px 55px"
        >
          <Typo.Body2>ชวนเพื่อน</Typo.Body2>
        </ContentContainer>
        <ContentContainer margin="10px 0 0 0" minHeight="0" padding="10px 55px">
          <Flex align="center" justify="space-between" wrap="wrap">
            <div>
              <Typo.Body5 color="gray4" margin="5px 0 0 0">
                ลิ้งก์ชวนเพื่อน
              </Typo.Body5>
              <Typo.Body1
                break="break-word"
                id="btnCopyLink"
                margin="3px 0 0 0"
                color="textBlack"
              >
                {configs.isAffiliateOpen ? link[1].value : link[0].value}
              </Typo.Body1>
            </div>

            <Button
              // disabled
              background="primary"
              color="navy"
            >
              <Typo.Body4
                color="navy"
                lineHeight="normal"
                onClick={() => {
                  const copyText =
                    document.getElementById("btnCopyLink").innerHTML;
                  navigator.clipboard.writeText(copyText);
                  // navigator.clipboard.writeText(link[1].value);
                  toast("คัดลอกสำเร็จ", { icon: "success", autoClose: 1000 });
                }}
              >
                {/* ปิดปรับปรุงชั่วคราว */}
                คัดลอก
              </Typo.Body4>
            </Button>
          </Flex>
        </ContentContainer>
        <ContentContainer margin="10px 0 0 0" minHeight="0" padding="10px 55px">
          <Flex align="center" justify="space-between">
            <div>
              <Typo.Body5 color="gray4" margin="5px 0 0 0">
                ยอดเงินสะสม (บาท)
              </Typo.Body5>
              <Typo.Body1 margin="3px 0 0 0" color="textBlack">
                รายได้ชวนเพื่อน{" "}
                <IoMdInformationCircleOutline
                  style={{
                    cursor: "help",
                    color: "rgba(245,194,64,1)",
                  }}
                  onClick={() =>
                    window.open(
                      "https://www.hongthongofficial.com/home/becometopartner",
                      "_blank"
                    )
                  }
                />
              </Typo.Body1>
            </div>
            <Typo.H4
              className="gradient"
              margin="8px 0 0 0"
              color="newGrReverse"
            >
              ฿ {new Intl.NumberFormat().format(profileState.buyCash || 0)}
            </Typo.H4>
          </Flex>
        </ContentContainer>

        {/* //////อัปเดตแจ้งเตือน */}
        <ContentContainer
          margin="32px 0 0 0"
          minHeight="0"
          bgcolor="newGrReverse"
          padding="10px 55px"
        >
          <Flex align="center" justify="space-between">
            <Typo.Body2>อัปเดตการแจ้งเตือน</Typo.Body2>
            {!isEditingNoti && (
              <Button
                background={"white"}
                color="navy"
                padding="0px 8px"
                radius="7px"
                onClick={() => setIsEditingNoti(true)}
              >
                แก้ไข
              </Button>
            )}
          </Flex>
        </ContentContainer>
        <ContentContainer margin="10px 0 0 0" minHeight="0" padding="10px 55px">
          <Typo.Body5 color="gray4" margin="5px 0 0 0">
            ช่องทาง
          </Typo.Body5>
          {isEditingNoti ? (
            <>
              <Grid
                columns={2}
                justify="flex-start"
                margin="20px 0"
                columnsIpad={1}
                columnsMobile={1}
              >
                <div>
                  <input
                    type="radio"
                    id="takeSms"
                    name="messageChannel"
                    value="SMS"
                    checked={noti[0].value === "SMS"}
                    onChange={(e) =>
                      setNoti(() => [
                        {
                          key: noti[0].key,
                          value: e.target.value,
                        },
                      ])
                    }
                    style={{
                      marginRight: "10px",
                      transform: "scale(1.5)",
                      cursor: "pointer",
                    }}
                  />
                  <label htmlFor="takeSms" style={{ cursor: "pointer" }}>
                    แจ้งเตือนผ่านทาง SMS
                  </label>
                </div>

                {profileState.lineId ? (
                  <div>
                    <input
                      type="radio"
                      id="takeLine"
                      name="messageChannel"
                      value="LINE"
                      checked={noti[0].value === "LINE"}
                      onChange={(e) =>
                        setNoti(() => [
                          {
                            key: noti[0].key,
                            value: e.target.value,
                          },
                        ])
                      }
                      disabled={!isEditingNoti || !profileState.lineId}
                      style={{
                        marginRight: "10px",
                        transform: "scale(1.5)",
                        cursor: "pointer",
                      }}
                    />
                    <label htmlFor="takeLine" style={{ cursor: "pointer" }}>
                      แจ้งเตือนผ่านทาง Line
                    </label>{" "}
                  </div>
                ) : (
                  <div
                    style={{
                      minWidth: "200px",
                      display: "inline-block",
                      verticalAlign: "middle",
                      padding: "3px",
                    }}
                  >
                    <LineButton
                      text={"เชื่อมบัญชีไลน์"}
                      shouldCenter={false}
                      userId={profileState.userId}
                      appId={profileState.userNo}
                    />
                  </div>
                )}
              </Grid>
              <Button
                margin="15px 0"
                width="100%"
                background="primary"
                color="navy"
                onClick={() => {
                  setIsEditingNoti((prevValue) => {
                    const isSomeEmpty = noti.some((data) => !data.value);
                    if (prevValue && isSomeEmpty) {
                      toast("กรุณากรอกข้อมูลให้ครบทุกช่อง");
                      return prevValue;
                    }
                  });
                  save(noti);
                }}
              >
                <Typo.Body4 color="navy" lineHeight="normal">
                  บันทึก
                </Typo.Body4>
              </Button>
            </>
          ) : (
            <Typo.Body1 margin="3px 0 0 0" color="textBlack">
              แจ้งเตือนผ่านช่องทาง {profileState.notifyType}
            </Typo.Body1>
          )}
        </ContentContainer>

        {/* //////ข้อมูลบัญชีรับเงินรางวัล */}
        <ContentContainer
          margin="32px 0 0 0"
          minHeight="0"
          bgcolor="newGrReverse"
          padding="10px 55px"
        >
          <Flex align="center" justify="space-between">
            <Typo.Body2>ข้อมูลบัญชีรับเงินรางวัล</Typo.Body2>
            {!profileState.bank?.accNo && !isEditingAccount && (
              <Button
                background={"white"}
                color="navy"
                padding="0px 8px"
                radius="7px"
                onClick={() => setIsEditingAccount(true)}
              >
                แก้ไข
              </Button>
            )}
          </Flex>
        </ContentContainer>
        <ContentContainer margin="10px 0 0 0" minHeight="0" padding="10px 55px">
          <Typo.Body5 color="gray4" margin="5px 0 0 0">
            ชื่อบัญชี
          </Typo.Body5>
          {isEditingAccount ? (
            <Input
              isEditing={isEditingAccount}
              placeHolder="ชื่อบัญชี"
              value={profileState.bank?.accName}
              key="bank.accName"
              type="input"
              setValue={(newValue) =>
                setAccount((x) => {
                  x[0].value = newValue;
                  return x;
                })
              }
            />
          ) : (
            <Typo.Body1 margin="3px 0 0 0" color="textBlack">
              {profileState.bank?.accName || "-"}
            </Typo.Body1>
          )}
          <Typo.Body5 color="gray4" margin="20px 0 0 0">
            ชื่อธนาคาร
          </Typo.Body5>
          {isEditingAccount ? (
            <Select
              type="select"
              defaultValue={getAccount[1]?.value || bankList}
              onChange={(e) => {
                const bank = bankList.find(
                  (bank) => bank.bankName === e.target.value
                );
                setAccount((prevState) => {
                  prevState[1].value = e.target.value;
                  prevState[3].value = bank.currency;
                  return prevState;
                });
              }}
            >
              <option></option>
              <optgroup label="Thai Bank">
                {bankList
                  .filter((bank) => bank.currency === "THB")
                  .map((x) => (
                    <option value={x.bankName}>{x.bankName}</option>
                  ))}
              </optgroup>

              <optgroup label="Lao Bank">
                {bankList
                  .filter((bank) => bank.currency === "LAK")
                  .map((x) => (
                    <option value={x.bankName}>{x.bankName}</option>
                  ))}
              </optgroup>
              {profileState.admin && (
                <>
                  <optgroup label="Korean Bank">
                    {bankList
                      .filter((bank) => bank.currency === "KRW")
                      .map((x) => (
                        <option value={x.bankName}>{x.bankName}</option>
                      ))}
                  </optgroup>
                </>
              )}
            </Select>
          ) : (
            <Typo.Body1 margin="3px 0 0 0" color="textBlack">
              {profileState.bank?.name || "-"}
            </Typo.Body1>
          )}
          <Typo.Body5 color="gray4" margin="20px 0 0 0">
            เลขบัญชี
          </Typo.Body5>
          {isEditingAccount ? (
            <Input
              isEditing={isEditingAccount}
              placeHolder="เลขบัญชี"
              value={profileState.bank?.accNo}
              key="bank.accNo"
              type="input"
              setValue={(newValue) =>
                setAccount((x) => {
                  x[2].value = newValue;
                  return x;
                })
              }
            />
          ) : (
            <Typo.Body1 margin="3px 0 0 0" color="textBlack">
              {profileState.bank?.accNo || "-"}
            </Typo.Body1>
          )}
          {isEditingAccount && (
            <Button
              margin="15px 0"
              width="100%"
              background="primary"
              color="navy"
              onClick={() => {
                setIsEditingAccount((prevValue) => {
                  const isSomeEmpty = account.some((data) => !data.value);
                  if (prevValue && isSomeEmpty) {
                    toast("กรุณากรอกข้อมูลให้ครบทุกช่อง");
                    return prevValue;
                  }
                });
                save(account);
              }}
            >
              <Typo.Body4 color="navy" lineHeight="normal">
                บันทึก
              </Typo.Body4>
            </Button>
          )}
        </ContentContainer>

        <ContentContainer
          margin="32px 0 0 0"
          minHeight="0"
          bgcolor="newGrReverse"
          padding="10px 55px"
        >
          <Flex align="center" justify="space-between">
            <Typo.Body2>ข้อมูลที่อยู่จัดส่ง</Typo.Body2>
            {!isEditingAddress && (
              <Button
                background={"white"}
                color="navy"
                padding="0px 8px"
                radius="7px"
                onClick={() => setIsEditingAddress(true)}
              >
                แก้ไข
              </Button>
            )}
          </Flex>
        </ContentContainer>
        <ContentContainer margin="10px 0 0 0" minHeight="0" padding="10px 55px">
          <Typo.Body5 color="gray4" margin="5px 0 0 0">
            ที่อยู่
          </Typo.Body5>
          {isEditingAddress ? (
            <Input
              isEditing={isEditingAddress}
              placeHolder="ที่อยู่"
              value={profileState.address.addr}
              key="address.addr"
              type="input"
              setValue={(newValue) => {
                setAddress((x) => {
                  x[1].value = newValue;
                  return x;
                });

                console.log(address);
              }}
            />
          ) : (
            <Typo.Body1 margin="3px 0 0 0" color="textBlack">
              {profileState.address.addr || "-"}
            </Typo.Body1>
          )}

          <Grid
            columns={2}
            justify="flex-start"
            columnsIpad={1}
            columnsMobile={1}
            justifyMobile="flex-start"
          >
            <div>
              <Typo.Body5 color="gray4" margin="20px 0 0 0">
                จังหวัด
              </Typo.Body5>
              {isEditingAddress ? (
                <Select
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    setAddress((x) => {
                      x[0].value = value;
                      return x;
                    });
                  }}
                  defaultValue={"--โปรดเลือก--"}
                >
                  {address[0].value ? (
                    <option value={address[0].value}>
                      {address[0].value} (ตัวเลือกปัจจุบัน)
                    </option>
                  ) : (
                    <option value={null}>--โปรดเลือก--</option>
                  )}
                  {address[0].choices.map((y) => (
                    <option key={y} value={y}>
                      {y}
                    </option>
                  ))}
                </Select>
              ) : (
                <Typo.Body1 margin="3px 0 0 0" color="textBlack">
                  {profileState.address.province || "-"}
                </Typo.Body1>
              )}
            </div>
            <div margin="20px 0">
              <Typo.Body5 color="gray4" margin="20px 0 0 0">
                รหัสไปรษณีย์
              </Typo.Body5>
              {isEditingAddress ? (
                <Input
                  isEditing={isEditingAddress}
                  placeHolder="ที่อยู่"
                  value={profileState.address.zipcode}
                  key="address.zipcode"
                  type="input"
                  setValue={(newValue) => {
                    setAddress((x) => {
                      x[2].value = newValue;
                      return x;
                    });
                  }}
                />
              ) : (
                <Typo.Body1 margin="3px 0 0 0" color="textBlack">
                  {profileState.address.zipcode || "-"}
                </Typo.Body1>
              )}
            </div>
          </Grid>
          {isEditingAddress && (
            <Button
              margin="15px 0"
              width="100%"
              background="primary"
              color="navy"
              onClick={() => {
                setIsEditingAddress((prevValue) => {
                  const isSomeEmpty = address.some((data) => !data.value);
                  if (prevValue && isSomeEmpty) {
                    toast("กรุณากรอกข้อมูลให้ครบทุกช่อง");
                    return prevValue;
                  }
                });
                save(address);
              }}
            >
              <Typo.Body4 color="navy" lineHeight="normal">
                บันทึก
              </Typo.Body4>
            </Button>
          )}
        </ContentContainer>
        {/* ///////////policy */}
        <ContentContainer
          margin="32px 0 0 0"
          minHeight="0"
          bgcolor="newGrReverse"
          padding="10px 55px"
        >
          <Typo.Body2>ข้อกำหนดและเงื่อนไข</Typo.Body2>
        </ContentContainer>
        <ContentContainer
          margin="10px 0 0 0"
          minHeight="0"
          padding="10px 55px"
          onClick={() => setIsOpenApp(!isOpenApp)}
          cursor="pointer"
        >
          <Flex align="center" justify="space-between">
            <Typo.Body6 margin="3px 0 0 0" color="textBlack">
              ข้อตกลงและเงื่อนไขของแอปฯหงษ์ทอง
            </Typo.Body6>
            {isOpenApp ? (
              <FiChevronDown style={{ color: themes.colors.primary }} />
            ) : (
              <FiChevronRight style={{ color: themes.colors.primary }} />
            )}
          </Flex>
          <Collapse isOpen={isOpenApp}>
            <h6
              style={{
                margin: "1em",
                textAlign: "left",
                lineHeight: "25px",
              }}
            >
              ฉัน ในฐานะผู้ซื้อลอตเตอรี่ ตกลงและทราบดีว่า.
              หงษ์ทองไม่จำหน่ายลอตเตอรี่ในสถานศึกษา และให้กับบุคคลที่อายุต่ำกว่า
              20 ปี ทางหงษ์ทอง ขอสงวนสิทธิ์ในการดำเนินคดีตามกฎหมายสูงสุด
              ทั้งทางแพ่งและอาญา กับผู้ที่มาซื้อลอตเตอรี่กับหงษ์ทอง
              โดยมีอายุต่ำกว่า 20 ปีบริบูรณ์ หรือซื้อในสถานศึกษา
            </h6>
            <h6
              style={{
                margin: "0 1em 1em 1em",
                textAlign: "left",
                lineHeight: "25px",
              }}
            >
              ฉันตกลงยินยอม และยอมรับเงื่อนไขต่างๆ ที่ทางหงษ์ทองกำหนดขึ้น
              ทั้งในปัจจุบันและหรือในอนาคต เพื่อตรวจสอบอายุผู้ซื้อลอตเตอรี่
              ว่าไม่ต่ำกว่า 20 ปีบริบูรณ์
            </h6>
            <h6
              style={{
                margin: "0 1em 1em 1em",
                textAlign: "left",
                lineHeight: "25px",
              }}
            >
              หงษ์ทองขอสงวนสิทธิ์ในการขอตรวจสอบอายุผู้ซื้อ
              และฉันตกลงยอมจ่ายค่าปรับให้กับทางหงษ์ทอง เป็นเงิน 10,000
              (หนึ่งหมื่นบาทถ้วน) ต่อการซื้อลอตเตอรี่ 1 ใบ
              ในกรณีที่หงษ์ทองตรวจพบว่า ฉันซื้อลอตเตอรี่ในสถานศึกษา
              และหรืออายุฉันยังไม่ถึง 20 ปีบริบูรณ์
            </h6>
            <h6
              style={{
                margin: "0 1em 1em 1em",
                textAlign: "left",
                lineHeight: "25px",
              }}
            >
              ฉันตกลงและทราบดีว่า
              ทางหงษ์ทองขอสงวนสิทธิ์ในการไม่โอนเงินรางวัลในกรณีผู้ซื้อถูกรางวัล
              และจะไม่คืนเงินค่าซื้อลอตเตอรี่ให้กับผู้ซื้อลอตเตอรี่.
              ในกรณีที่หงษ์ทองตรวจพบว่า ผู้ซื้อมีอายุต่ำกว่า 20 ปีบริบูรณ์ **
            </h6>

            <Flex>
              <input
                id="accept"
                defaultChecked={true}
                disabled={true}
                value={true}
                type={"checkbox"}
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "1em",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              />
              <h6
                style={{
                  margin: "0 1em 1em 1em",
                  textAlign: "left",
                  lineHeight: "25px",
                  fontWeight: "400",
                }}
              >
                ขอรับรองว่า ฉันไม่ได้ซื้อลอตเตอรี่ในสถานศึกษา และ
                ปัจจุบันฉันมีอายุครบ 20 ปีบริบูรณ์
              </h6>
            </Flex>
          </Collapse>
        </ContentContainer>

        <ContentContainer
          margin="10px 0 0 0"
          minHeight="0"
          padding="10px 55px"
          onClick={() => setIsOpenBuy(!isOpenBuy)}
          cursor="pointer"
        >
          <Flex align="center" justify="space-between">
            <Typo.Body6 margin="3px 0 0 0" color="textBlack">
              จัดการข้อมูลการให้การยินยอม PDPA
            </Typo.Body6>
            {isOpenBuy ? (
              <FiChevronDown style={{ color: themes.colors.primary }} />
            ) : (
              <FiChevronRight style={{ color: themes.colors.primary }} />
            )}
          </Flex>

          <Collapse isOpen={isOpenBuy}>
            <h6
              style={{
                margin: "1em",
                textAlign: "left",
                lineHeight: "25px",
              }}
            >
              ข้อกำหนดและเงื่อนไขฉบับนี้ ถือเป็นข้อตกลงระหว่าง บริษัท หงษ์ทอง
              ลอตเตอรี่ออนไลน์ จำกัด กับผู้ซื้อหรือผู้รับบริการ
            </h6>
            <h6
              style={{
                margin: "0 1em 1em 1em",
                textAlign: "left",
                lineHeight: "25px",
              }}
            >
              1. “ผู้ซื้อ” หมายถึง ผู้ขอใช้บริการ ผู้ใช้บริการ สมาชิก
              ตลอดจนผู้มีส่วนเกี่ยวข้อง{" "}
            </h6>
            <h6
              style={{
                margin: "0 1em 1em 1em",
                textAlign: "left",
                lineHeight: "25px",
              }}
            >
              2. “บริการ” หมายถึง
              บริการของผู้ให้บริการภายใต้ข้อกำหนดและเงื่อนไขฉบับนี้{" "}
            </h6>
            <h6
              style={{
                margin: "0 1em 1em 1em",
                textAlign: "left",
                lineHeight: "25px",
              }}
            >
              3.“ผู้ให้บริการ” หมายถึง บริษัท หงษ์ทอง ลอตเตอรี่ออนไลน์ จำกัด
            </h6>
            <h6
              style={{
                margin: "0 1em 1em 1em",
                textAlign: "left",
                lineHeight: "25px",
              }}
            >
              4. “บริษัทฯ” หมายถึง บริษัท หงษ์ทอง ลอตเตอรี่ออนไลน์ จำกัด{" "}
            </h6>
            <h6
              style={{
                margin: "0 1em 1em 1em",
                textAlign: "left",
                lineHeight: "25px",
              }}
            >
              5. “สลากกินแบ่งรัฐบาล” หมายถึง
              สลากกินแบ่งรัฐบาลที่ออกโดยสำนักงานสลากกินแบ่งรัฐบาลทุกประเภท{" "}
            </h6>
            <h6
              style={{
                margin: "0 1em 1em 1em",
                textAlign: "left",
                lineHeight: "25px",
              }}
            >
              6. “แพลตฟอร์ม” หมายถึง “https://www.hongthongofficial.com” หรือ “
              https://shop.hongthongofficial.com ” หรือ “แอปพลิเคชันหงษ์ทอง.COM”
              ซึ่งเป็นบริษัทฯ ผู้ให้บริการจำหน่าย รับจัดเก็บ รับฝาก
              รับขึ้นเงินสลากกินแบ่งรัฐบาลตลอดจนประกอบกิจการอื่น ๆ
              ตามวัตถุประสงค์ของบริษัทฯ{" "}
            </h6>
            <h6
              style={{
                margin: "0 1em 1em 1em",
                textAlign: "left",
                lineHeight: "25px",
              }}
            >
              7. ผู้ซื้อยินยอมให้บริษัทฯ จัดเก็บข้อมูลส่วนบุคคล (Personal Data)
              ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคลพ.ศ.2562 PDPA (Personal
              Data Protection Act B.E. 2562 (2019)) เพื่อมีไว้ใช้และเปิดเผย
              ซึ่งข้อมูลส่วนบุคคล ได้แก่ชื่อ-นามสกุล หรือชื่อเล่น /
              เลขประจำตัวประชาชน, เลขหนังสือเดินทาง, เลขบัตรประกันสังคม,
              เลขใบอนุญาตขับขี่,เลขประจำตัวผู้เสียภาษี, เลขบัญชีธนาคาร,
              เลขบัตรเครดิต (การเก็บเป็นภาพสำเนาบัตรประชาชนหรือสำเนาบัตรอื่น
              ๆที่มีข้อมูลส่วนบุคคลที่กล่าวมาย่อมสามารถใช้ระบุตัวบุคคลได้โดยตัวมันเอง
              จึงถือเป็นข้อมูลส่วนบุคคล) / ที่อยู่, อีเมล์,เลขโทรศัพท์ /
              ข้อมูลอุปกรณ์หรือเครื่องมือ เช่น IP address, MAC address, Cookie
              ID / ข้อมูลระบุทรัพย์สินของบุคคลเช่น ทะเบียนรถยนต์, โฉนดที่ดิน /
              ข้อมูลที่สามารถเชื่อมโยงไปยังข้อมูลข้างต้นได้ เช่น
              วันเกิดและสถานที่เกิด,เชื้อชาติ,สัญชาติ, น้ำหนัก, ส่วนสูง,
              ข้อมูลตำแหน่งที่อยู่ (location), ข้อมูลการแพทย์, ข้อมูลการศึกษา,
              ข้อมูลทางการเงิน,ข้อมูลการจ้างงาน /
              ข้อมูลหมายเลขอ้างอิงที่เก็บไว้ในไมโครฟิล์ม
              แม้ไม่สามารถระบุไปถึงตัวบุคคลได้แต่หากใช้ร่วมกับระบบดัชนีข้อมูลอีกระบบหนึ่งก็จะสามารถระบุไปถึงตัวบุคคลได้
              /ข้อมูลการประเมินผลการทำงานหรือความเห็นของนายจ้างต่อการทำงานของลูกจ้าง
              / ข้อมูลบันทึกต่าง ๆที่ใช้ติดตามตรวจสอบกิจกรรมต่าง ๆ ของบุคคล เช่น
              log file
              /ข้อมูลที่สามารถใช้ในการค้นหาข้อมูลส่วนบุคคลอื่นในอินเทอร์เน็ต
              ตลอดจนข้อมูลส่วนบุคคลที่มีความละเอียดอ่อน(Sensitive Personal Data)
              ได้แก่ ข้อมูล เชื้อชาติ, เผ่าพันธุ์, ความคิดเห็นทางการเมือง,
              ความเชื่อในลัทธิศาสนาหรือปรัชญา, พฤติกรรมทางเพศ, ประวัติอาชญากรรม,
              ข้อมูลสุขภาพ ความพิการ หรือข้อมูลสุขภาพจิต,ข้อมูลสหภาพแรงงาน,
              ข้อมูลพันธุกรรม, ข้อมูลชีวภาพ, ข้อมูลทางชีวมิติ (Biometric) เช่น
              รูปภาพใบหน้า, ลายนิ้วมือ,ฟิล์มเอกซเรย์, ข้อมูลสแกนม่านตา,
              ข้อมูลอัตลักษณ์เสียง, ข้อมูลพันธุกรรม
              เป็นต้นและข้อมูลอื่นใดซึ่งกระทบต่อเจ้าของข้อมูลในทำนองเดียวกันตามที่คณะกรรมการประกาศกำหนด{" "}
            </h6>
            <Flex>
              <input
                id="accept"
                defaultChecked={true}
                disabled={true}
                value={true}
                type={"checkbox"}
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "1em",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              />
              <h6
                style={{
                  margin: "0 1em 1em 1em",
                  textAlign: "left",
                  lineHeight: "25px",
                  fontWeight: "400",
                }}
              >
                ข้าพเจ้าเข้าใจดีว่า ผู้ให้บริการ จะเก็บรวบรวม ใช้
                และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าเพื่อวัตถุประสงค์ในการให้บริการตามสัญญานี้
                การวิเคราะห์ข้อมูลเพื่อวางแผนทางการตลาดการนำเสนอสินค้าและบริการอื่นๆ
                ของผู้ให้บริการแก่ข้าพเจ้า รวมถึงวัตถุประสงค์อื่นๆ
                ตามที่ผู้ให้บริการเห็นสมควร
              </h6>
            </Flex>
          </Collapse>
        </ContentContainer>

        <Button
          background="dangerRed"
          hoverBg="white"
          hoverColor="dangerRed"
          hoverBorder="dangerRed"
          width="100%"
          margin="32px 0 20px 0"
          size="18px"
          weight={700}
          onClick={() => go("/logout")}
        >
          ออกจากระบบ
        </Button>
      </Container>
    </Flex>

    // <Modal
    //   style={{
    //     display: "flex",
    //     alignItems: "center",
    //     justifyContent: "center",
    //     height: "100%",
    //     margin: "0 auto",
    //   }}
    //   isOpen={modalWithdraw}
    //   toggle={toggle}
    // >
    //   <ModalBody style={{}}>
    //     <div>
    //       <Label.H3 weight={600}>
    //         โปรดใส่จำนวนที่ต้องการ (ขั้นต่ำ 100 บาท)
    //       </Label.H3>
    //       <Label.H5>จำนวนสูงสุดที่ถอนได้ {profileState.buyCash} บาท</Label.H5>
    //       <br />
    //       <InputGroup>
    //         <ReactstrapInput
    //           id="withdraw"
    //           name="number"
    //           placeholder="ถอนขั้นต่ำ 100 บาท"
    //           type="text"
    //           onChange={(e) => setWithdraw(e.target.value)}
    //         />
    //         <InputGroupText>฿</InputGroupText>
    //       </InputGroup>

    //       <br />
    //       <Flex>
    //         {profileState.admin && (
    //           // withdraw >= 100 && withdraw <= profileState.buyCash && (
    //           <ModalButton onClick={() => withdrawCash()}>
    //             ถอนเงิน
    //           </ModalButton>
    //         )}
    //         <ModalButton className="dismiss" onClick={toggle}>
    //           ยกเลิก
    //         </ModalButton>
    //       </Flex>
    //     </div>
    //   </ModalBody>
    // </Modal>
  );
};

const Avatar = styled.div`
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 0;

  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  border: 4px solid ${themes.colors.yellow};

  cursor: pointer;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

const BlueHead = ({
  header,
  children,
  save,
  notEditable,
  InputGridA,
  isEditing,
  setIsEditing,
  disabled,
  value,
}) => {
  const { toast } = Context();
  return (
    <WhiteHolder>
      <TextBody>
        <Flex align="center">
          <Header>{header}</Header>
          {!notEditable && (
            <Button
              isEditing={isEditing}
              onClick={() =>
                setIsEditing((x) => {
                  const isSomeEmpty = value.some((data) => !data.value);
                  if (isEditing && isSomeEmpty) {
                    toast("กรุณากรอกข้อมูลให้ครบทุกช่อง");
                    return x;
                  }
                  x && save();
                  return !x;
                })
              }
              className={isEditing && "save"}
            >
              {isEditing ? (
                <Flex align="center">
                  <FiSave style={{ marginRight: "3px" }} /> บันทึก
                </Flex>
              ) : (
                <Flex align="center">
                  <FiEdit style={{ marginRight: "3px" }} />
                  แก้ไข
                </Flex>
              )}
            </Button>
          )}
        </Flex>

        <div style={{ textAlign: "center", marginTop: "10px" }}>{children}</div>
      </TextBody>
    </WhiteHolder>
  );
};
const Header = styled.p`
  text-align: left;
  margin: 0px;
  font-size: 1.2em;
  font-weight: bold;
  color: rgb(50, 101, 150);
`;

const ModalButton = styled.button`
  background-color: ${themes.colors.primary};
  border-radius: 0.235rem;
  margin-right: 15px;
  font-size: 1.25em;
  border: 0;
  &.dismiss {
    background-color: ${themes.colors.dangerRed};
    color: white;
  }
`;

const LogoutButton = styled.button`
  max-width: 750px;
  width: 100%;
  background-color: ${themes.colors.dangerRed};
`;
// const Button = styled.div`
//   margin: 0 1em;
//   background-color: ${themes.colors.primary};
//   border: 2px solid ${themes.colors.primary};
//   border-radius: 0.375rem;
//   font-size: 0.75rem;
//   font-weight: 500;
//   padding: 0.05rem 0.5rem;
//   transition: 0.1s;
//   cursor: pointer;
//   &.save {
//     color: ${themes.colors.white};
//     border: 2px solid ${themes.colors.success};
//     background-color: ${themes.colors.success};
//   }
//   :hover {
//     background-color: ${themes.colors.white};
//     border: 2px solid ${themes.colors.primary};
//     color: ${themes.colors.primary};
//     &.save {
//       border: 2px solid ${themes.colors.success};
//       color: ${themes.colors.success};
//     }
//   }
// `;
const Input = (props) => {
  const {
    value,
    placeHolder,
    setValue,

    handleClick,
    readOnly,
    onKeyPress,
  } = props;
  const inputRef = useRef(null);
  return (
    <div
      style={{ width: "100%", textAlign: "left", display: "inline-block" }}
      onClick={() => handleClick && handleClick({ ...props, ref: inputRef })}
    >
      <InputSc
        ref={inputRef}
        readOnly={readOnly}
        placeholder={placeHolder}
        onKeyPress={onKeyPress}
        defaultValue={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
};

const InputSc = styled.input`
  text-align: left;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid ${themes.colors.navy};
  color: ${themes.colors.lightBlack};
  :focus-visible {
    border: 1px solid gray;
  }
`;
const Select = styled.select`
  text-align: left;
  width: 100%;
  padding: 4px 5px;
  border-radius: 5px;
  border: 1px solid ${themes.colors.navy};
  :focus-visible {
    border: 1px solid gray;
  }
`;
