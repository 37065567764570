/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { ContextInit } from "./Context";
import { webConfigs, unprotectedPaths } from "./constants";
import { db } from "./util/firebase";
import themes from "./themes";

import Gate from "./components/Gate";

import {
  Cart,
  CheckReward,
  Closing,
  Complain,
  Donate,
  DonateDetail,
  DonateHistory,
  Coupon,
  CouponPayment,
  CouponHistory,
  DeliverChannel,
  Histories,
  HistoryDetail,
  Home,
  Logout,
  PaymentDetail,
  Orders,
  Profile,
  ReceiverPerson,
  Register,
  RegisterOtp,
  ResetPassword,
  RegisterSuccess,
  Status,
  Login,
  LandingPage,
  ShopHistory,
  Shop,
  ShopPayment,
  PointProducts,
  PointRedeem,
  PointHistory,
} from "./pages";

import {
  Loading,
  ModalAddress,
  ModalConsent,
  ModalLoading,
  ModalRequiredInformation,
  DesktopCartFooter,
  ModalSpecial,
  Navbar,
  Footer,
} from "./components";

function App() {
  const navigate = useNavigate();

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [modalConsent, setModalConsent] = useState({ isShow: false });
  const [showModalSpecial, setModalSpecial] = useState(false);
  const showModalConsent = (detail) =>
    setModalConsent({ ...detail, isShow: true });

  const [modalAddress, setModalAddress] = useState({ isShow: false });
  const showModalAddress = (detail) => {
    setModalAddress({ ...detail, isShow: false });
  };

  const [modalLoading, setModalLoading] = useState({ isShow: false });
  const showModalLoading = (detail) => setModalLoading({ isShow: true });
  const hideModalLoading = (detail) => setModalLoading({ isShow: false });
  const [modalLogin, setModalLogin] = useState({ isShow: false });
  const showModalLogin = (detail) => setModalLogin({ ...detail, isShow: true });

  const [showModalRequiredInformation, setShowModalRequiredInformation] =
    useState({ isShow: false });

  const hideModal = () => {
    setModalConsent({ isShow: false });
    setModalLoading({ isShow: false });
    setModalLogin({ isShow: false });
    setModalAddress({ isShow: false });
  };
  //modal function --------------------------------------------------
  const [searchParams] = useSearchParams();

  const toastCall = (text, options = {}) => {
    Swal.fire({
      title: text,
      icon: options.icon || "error",
      timer: options.confirmButton?.show ? 0 : options.autoClose || 3000,
      showConfirmButton: options.confirmButton?.show || false,
      confirmButtonText: options.confirmButton?.text || "confirm",
      showCancelButton: options.cancelButton?.show || false,
      cancelButtonText: options.cancelButton?.text || "cancel",
      buttonsStyling: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (options.confirmButton.function?.go) {
          go(options.confirmButton.function.go);
        }
      }
      if (result.dismiss) {
        if (options.refreshAfterToast) {
          window.location.reload();
        }
        if (options.dismissFunction?.go) {
          go(options.dismissFunction.go);
        }
      }
    });
  };
  const [configs, setConfigs] = useState({});
  const [profileState, setProfileState] = useState({});
  const [cart, setCart] = useState({});
  const [sessions, setSessions] = useState({});

  const callApi = async (path, body = {}, options = {}) => {
    const url = path.includes("http") ? path : webConfigs.api + path;
    !options.notShowLoading && setModalLoading({ isShow: true });
    try {
      var data = JSON.stringify(body);
      var axiosConfigs = {
        method: body ? "post" : "get",
        url,
        headers: {
          "Content-Type": body.append
            ? "application/x-www-form-urlencoded"
            : "application/json",
          accessToken: localStorage.accessToken,
          action: path,
          ...configs,
          ...body.configs,
        },
        data,
      };
      const response = await axios(axiosConfigs);

      if (response.data.code === 403) {
        localStorage.removeItem("accessToken");
        toastCall(
          response.data.toastMessage ||
            "บัญชีของท่านถูกระงับชั่วคราว กรุณาติดต่อแอดมิน",
          { dismissFunction: { go: "/logout" } }
        );

        return false;
      }
      if (response.data.code === 401) {
        localStorage.removeItem("accessToken");
        toastCall(
          response.data.toastMessage || "ไม่สามารถทำรายการได้ โปรดลองอีกครั้ง",
          {
            refreshAfterToast: response.data.refreshAfterToast,
            autoClose: response.data.autoClose,
            dismissFunction: { go: "/logout" },
          }
        );

        return false;
      }
      // console.log(axiosConfigs.url, axiosConfigs.headers, body, response);
      !options.notShowLoading && setModalLoading({ isShow: false });
      if (
        (response.data.code === 500 || response.data.toastMessage) &&
        !options.background
      ) {
        toastCall(
          response.data.toastMessage || "ไม่สามารถทำรายการได้ โปรดลองอีกครั้ง",

          {
            refreshAfterToast: response.data.refreshAfterToast,
            autoClose: response.data.autoClose,
            icon: response.data.icon,
            confirmButton: response.data.confirmButton,
          }
        );

        return false;
      }

      if (response.data.refresh) {
        window.location.reload();
      }
      return response.data;
    } catch (err) {
      !options.notShowLoading && setModalLoading({ isShow: false });
      // toastCall("ไม่สามารถทำรายการได้ โปรดลองใหม่ภายหลัง");

      return false;
    }
  };

  const promiseAllCallApi = async (callApis, options = {}) => {
    !options.notShowLoading && setModalLoading({ isShow: true });
    try {
      !options.notShowLoading && setModalLoading({ isShow: false });
      return await Promise.all(callApis);
    } catch (err) {
      !options.notShowLoading && setModalLoading({ isShow: false });
      return [];
    }
  };

  const go = (path) => {
    const query = Object.keys(sessions).reduce((acc, key) => {
      return sessions[key] ? acc + key + "=" + sessions[key] + "&" : acc;
    }, "");
    if (path.includes("http")) {
      window.location.replace(path);
    } else {
      navigate(path + "?" + query);
    }
  };
  const loginOldUser = async (configs) => {
    const lineId = searchParams.get("lineid");
    const token =
      searchParams.get("token") ||
      searchParams.get("adminToken") ||
      localStorage.getItem("accessToken") ||
      "";
    if (token) {
      localStorage.setItem("accessToken", token);
      const userResult = await callApi(
        "users-getUserInfo",
        {},
        { notShowLoading: true }
      );

      if (userResult.statusCode === 200) {
        if (
          !userResult.data.firstName ||
          !userResult.data.lastName ||
          !userResult.data.phone
        ) {
          setShowModalRequiredInformation({ isShow: true });
        }
        if (!userResult.data.lineId && lineId) {
          await callApi("users-editSelf", {
            fields: [{ key: "lineId", value: lineId }],
            id: userResult.userId,
          });
          window.location.reload();
        }
        setProfileState(userResult.data);
      } else if (userResult.code === 401) {
        toastCall(
          "บัญชีผู้ใช้หรือรหัสผ่านไม่ถูกต้อง หากคุณกรอกถูกต้องแล้วแต่ไม่สามารถเข้าสู่ระบบได้ กรุณาเปลี่ยนรหัสผ่าน"
        );
        localStorage.removeItem("accessToken");
      } else if (userResult.code === 500) {
        toastCall("บัญชีผู้ใช้หรือรหัสผ่านไม่ถูกต้อง");
        localStorage.removeItem("accessToken");
      } else {
        console.log("userResult", userResult);
        toastCall("ระบบภายในมีปัญหา โปรดลองใหม่ภายหลัง");
      }
    }
  };

  const updateProfileState = async () => {
    const userResult = await callApi(
      "users-getUserInfo",
      {},
      { notShowLoading: true }
    );

    setProfileState(userResult.data);
  };

  const setParamToSessionStorage = (param, sessionKey) => {
    const paramValue = searchParams.get(param);

    if (!!paramValue) {
      sessionStorage.setItem(sessionKey, paramValue);
    }
  };

  useEffect(() => {
    if (modalConsent.isShow || modalLoading.isShow || modalAddress.isShow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [modalConsent, modalLoading, modalAddress]);
  useEffect(() => {
    (async () => {
      const adminToken = searchParams.get("adminToken");
      if (adminToken) {
        localStorage.setItem("accessToken", adminToken);
        localStorage.setItem("adminToken", adminToken);
      }
      if (localStorage.getItem("adminToken")) {
        const timeOut = 1000 * 60 * 30; // 30 minutes to logout as customer
        toastCall("The system will be logged out automatically!", "warning", {
          autoClose: timeOut,
          closeOnClick: false,
          pauseOnHover: false,
          pauseOnFocusLoss: true,
          position: "bottom-right",
          dismissFunction: { go: "/logout" },
        });
      }

      const getConfig = async () => {
        const getConfigs = await callApi("configs-getConfig/", undefined, {
          notShowLoading: true,
        });
        let newConfigs = { ...webConfigs, ...getConfigs };
        if (searchParams.get("isAdmin") === "yes") {
          console.log("Amdin test");
          newConfigs.isOpen.store = true;
        }
        setConfigs(newConfigs);
      };
      await promiseAllCallApi([getConfig(), loginOldUser()]);
      setIsDataLoaded(true);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      document.body.onkeydown = (e) => {
        if (e.key === "Escape") {
          hideModal();
        }
      };
      return () => (document.body.onkeydown = null);
    })();
  }, []);
  useEffect(() => {
    if (configs.isAffiliateOpen) {
      setParamToSessionStorage("affiliate", "affiliate");
    } else {
      setParamToSessionStorage("refcode", "referrer");
    }

    const influencer = searchParams.get("influencer");
    setSessions((old) => {
      return { ...old, influencer };
    });
  }, [configs]);
  let location = useLocation();

  const ctx = {
    //Modals
    hideModal,
    showModalLoading,

    modalConsent,
    showModalConsent,

    modalLogin,
    showModalLogin,
    loginOldUser,
    ModalSpecial,
    setModalSpecial,
    modalAddress,
    showModalAddress,
    //utils
    setModalLoading,
    hideModalLoading,
    db,
    toast: toastCall,
    go,
    callApi,
    promiseAllCallApi,

    //userData
    profileState,
    setProfileState,
    updateProfileState,

    //systemData
    sessions,
    setSessions,
    configs,
    setConfigs,
    cart,
    setCart,
  };

  return (
    <ContextInit.Provider value={ctx}>
      {modalConsent.isShow && <ModalConsent {...modalConsent} />}
      {modalLoading.isShow && <ModalLoading />}
      {modalAddress.isShow && <ModalAddress />}
      {showModalSpecial && <ModalSpecial />}
      <ModalRequiredInformation
        isOpen={showModalRequiredInformation.isShow}
        setShowModalRequiredInformation={setShowModalRequiredInformation}
      />
      <div>{sessions.influencer}</div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Navbar />

      {isDataLoaded ? (
        <Routes>
          <Route
            exact
            path="/login"
            element={
              <GoogleReCaptchaProvider reCaptchaKey="6Lcs0VknAAAAAKN-oVvWqdE_sOYsAAurvigzay8k">
                <Gate
                  bg={themes.colors.gradient2}
                  isRequireLogin={false}
                  // isRequireOpening={true}
                  Component={Login}
                  key={"/login"}
                />
              </GoogleReCaptchaProvider>
            }
          />
          <Route
            exact
            path="/HT/:refcode"
            element={
              <Gate
                bg={themes.colors.gradient2}
                isRequireLogin={false}
                Component={Register}
                key={"/register/:refcode"}
              />
            }
          />
          <Route
            exact
            path="/register"
            element={
              <Gate
                bg={themes.colors.gradient2}
                isRequireLogin={false}
                Component={Register}
                key={"/register"}
              />
            }
          />

          <Route
            exact
            path=""
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireLogin={false}
                Component={LandingPage}
                key={""}
              />
            }
          />
          <Route
            exact
            path="/shop"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireLogin={false}
                isRequireOpening={true}
                Component={Home}
                key={"/"}
              />
            }
          />
          <Route
            exact
            path="/"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireLogin={false}
                Component={LandingPage}
                key={"/"}
              />
            }
          />
          <Route
            exact
            path="search"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireLogin={false}
                isRequireOpening={true}
                Component={Home}
                key={"search"}
              />
            }
          />
          <Route
            exact
            path="search/:filterPath"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireLogin={false}
                isRequireOpening={true}
                Component={Home}
                key={"search/:filterPath"}
              />
            }
          />
          <Route
            exact
            path="search/:filterPath/:digitsPath"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireLogin={false}
                isRequireOpening={true}
                Component={Home}
                key={"search/:filterPath/:digitsPath"}
              />
            }
          />
          <Route
            exact
            path="/logout"
            element={
              <Gate
                bg={"yellowGradian"}
                isRequireLogin={false}
                Component={Logout}
                key={"/logout"}
              />
            }
          />
          <Route
            exact
            path="/check-reward"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                Component={CheckReward}
                key={"/check-reward"}
                isRequireLogin={false}
              />
            }
          />

          <Route
            exact
            path="/register-success"
            element={
              <Gate
                bg={themes.colors.gradient2}
                isRequireLogin={false}
                Component={RegisterSuccess}
                key={"/register-success"}
              />
            }
          />
          <Route
            exact
            path="/reset-password"
            element={
              <Gate
                bg={themes.colors.gradient2}
                isRequireLogin={false}
                Component={ResetPassword}
                key={"/reset-password"}
              />
            }
          />

          <Route
            exact
            path="/register-otp"
            element={
              <Gate
                bg={themes.colors.gradient2}
                Component={RegisterOtp}
                key={"/register-otp"}
              />
            }
          />

          <Route
            exact
            path="/complain"
            element={
              <GoogleReCaptchaProvider reCaptchaKey="6Lcs0VknAAAAAKN-oVvWqdE_sOYsAAurvigzay8k">
                <Gate
                  bg={"blueGradian"}
                  isRequireLogin={true}
                  Component={Complain}
                  key={"/complain"}
                />
              </GoogleReCaptchaProvider>
            }
          />

          <Route
            exact
            path="/closing"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireLogin={false}
                Component={Closing}
                key={"/closing"}
              />
            }
          />

          <Route
            exact
            path="/cart"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireOpening={true}
                Component={Cart}
                key={"/cart"}
              />
            }
          />

          <Route
            exact
            path="/coupon"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireLogin={true}
                Component={Coupon}
                key={"/coupon"}
              />
            }
          />

          <Route
            exact
            path="/coupon/:param"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireLogin={true}
                Component={Coupon}
                key={"/coupon/:param"}
              />
            }
          />

          <Route
            exact
            path="/coupon/payment/:couponId"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireLogin={true}
                Component={CouponPayment}
                key={"/coupon/payment/:couponId"}
              />
            }
          />

          <Route
            exact
            path="/coupon/history"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireLogin={true}
                Component={CouponHistory}
                key={"/coupon/history"}
              />
            }
          />
          <Route
            exact
            path="/point/products"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireLogin={true}
                Component={PointProducts}
                key={"/point/products"}
              />
            }
          />
          <Route
            exact
            path="/point/product/:productId"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireLogin={true}
                Component={PointRedeem}
                key={"/point/product/:productId"}
              />
            }
          />
          <Route
            exact
            path="/point/history"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireLogin={true}
                Component={PointHistory}
                key={"/point/history"}
              />
            }
          />

          <Route
            exact
            path="/shop/products"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireLogin={true}
                Component={Shop}
                key={"/shop/products"}
              />
            }
          />
          <Route
            exact
            path="/shop/product/:productId"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireLogin={true}
                Component={ShopPayment}
                key={"/shop/product/:productId"}
              />
            }
          />
          <Route
            exact
            path="/shop/history"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireLogin={true}
                Component={ShopHistory}
                key={"/shop/history"}
              />
            }
          />

          {/* <Route
            exact
            path="/oldGiftshop"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireLogin={true}
                Component={OldGiftshop}
                key={"/oldGiftshop"}
              />
            }
          />

          <Route
            exact
            path="/oldGiftshop/:oldGiftshop"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireLogin={true}
                Component={OldGiftshop}
                key={"/oldGiftshop/:oldGiftshop"}
              />
            }
          /> */}
          <Route
            exact
            path="/donate"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireLogin={true}
                Component={Donate}
                key={"/donate"}
              />
            }
          />
          <Route
            exact
            path="/donate/:donateId"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireLogin={true}
                Component={DonateDetail}
                key={"/donate/:donateId"}
              />
            }
          />

          <Route
            exact
            path="/donate/history"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireLogin={true}
                Component={DonateHistory}
                key={"/donate/history"}
              />
            }
          />

          <Route
            exact
            path="/orders"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                Component={Orders}
                key={"/orders"}
              />
            }
          />

          <Route
            exact
            path="/histories"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                Component={Histories}
                key={"/histories"}
                isRequireLogin={true}
              />
            }
          />
          <Route
            exact
            path="/deliverChannel"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireOpening={true}
                Component={DeliverChannel}
                key={"/deliverChannel"}
              />
            }
          />
          <Route
            exact
            path="/receiverPerson/:channel"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireOpening={true}
                Component={ReceiverPerson}
                key={"/receiverPerson/:channel"}
              />
            }
          />
          <Route
            exact
            path="/status/:status"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                Component={Status}
                key={"/status/:status"}
              />
            }
          />
          <Route
            exact
            path="/status/:status/:orderId"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                Component={Status}
                key={"/status/:status/:orderId"}
              />
            }
          />
          <Route
            exact
            path="/orders/:orderId"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                Component={PaymentDetail}
                key={"/orders/:orderId"}
                isRequireLogin={true}
              />
            }
          />
          <Route
            exact
            path="/histories/:orderId"
            element={
              <Gate
                bg={"bgMild"}
                Component={HistoryDetail}
                key={"/histories/:orderId"}
                isRequireLogin={true}
              />
            }
          />
          <Route
            exact
            path="/profile"
            element={
              <Gate
                bg={themes.colors.bgBlue}
                isRequireLogin={true}
                Component={Profile}
                key={"/profile"}
              />
            }
          />
        </Routes>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "linear-gradient(90deg, #002C7B 0%, #2065C4 100%)",
          }}
        >
          <Loading>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </Loading>
        </div>
      )}
      <DesktopCartFooter />
      <Footer />
    </ContextInit.Provider>
  );
}
export default App;
