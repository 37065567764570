import React, { useState } from "react";
import style from "../constants/style";
import Context from "../Context";
import styled from "styled-components";
import themes from "../themes";
export const StackImages = ({
  urls,
  isShowAllLotto,
  children,
  limitHeight,
  numbers = [],
  isDisable = false,
}) => {
  const { configs } = Context();
  const [height, setHeight] = useState(0);

  const blindDupLotto = (urlArr) => {
    const tempObj = [];

    return urlArr.map(([url, isMagic, isLabubu]) => {
      if (tempObj.includes(url)) {
        return ["xxx", isMagic, isLabubu];
      } else {
        tempObj.push(url);
        return [url, isMagic, isLabubu];
      }
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <Warper
        space={(urls.length + 4) * height}
        limitHeight={limitHeight && 5 * height}
      >
        {urls.length &&
          blindDupLotto(urls).map((url, i) => (
            <Div key={i}>
              <Image
                className={isShowAllLotto ? "no-stack" : ""}
                key={i}
                index={i}
                src={url[0]}
                space={height}
                isDisable={isDisable}
                ref={(el) => el && setHeight(parseInt(el.width * 0.1))}
              />
              {url[1] && (
                <Tag index={i}>
                  <img
                    src={configs.magicNumber.image}
                    alt="magicNumber"
                    width={"30px"}
                    height={"50px"}
                  />
                </Tag>
              )}
              {url[2] && (
                <Tag index={i}>
                  <img
                    src={configs.labubuNumber.image}
                    alt="labubuNumber"
                    width={"70px"}
                    height={"50px"}
                  />
                </Tag>
              )}
            </Div>
          ))}

        {children}
      </Warper>
      {!!numbers.length && numbers[0] && !urls[0] && (
        <div
          style={{
            position: "absolute",
            color: "white",
            textAlign: "left",
            top: 0,
          }}
        >
          <div>เลขที่ซื้อ</div>
          {numbers
            // .reduce((acc, x) => {
            //   if (acc.includes(x)) {
            //     acc.unshift("/images/error_lotto.jpg");
            //   } else {
            //     acc.push(x);
            //   }

            //   return acc;
            // }, [])
            .map((number) => (
              <div>{number}</div>
            ))}
        </div>
      )}
    </div>
  );
};
const Div = styled.div`
  position: relative;
  transition: transform 0.5s;
  &:hover {
    transform: translateY(-20px);
    cursor: pointer;
  }
`;
const Tag = styled.div`
  border-radius: 0 0 25px 25px;
  position: absolute;
  width: 15%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ index, space }) => index !== 0 && `-${35}%`};
  left: 0px;
  top: 0px;

  background: ${themes.colors.primary};
`;

const Warper = styled.div`
  position: relative;
  height: 100%;
  @media only screen and (max-width: 643px) {
    height: 100%;
  }
`;

const Image = styled.img`
  left: 0;
  width: 100%;
  margin-top: ${({ index, space }) => index !== 0 && `-${35}%`};
  transition-duration: 0.5s;
  &.no-stack {
    margin-top: 0;
    margin-bottom: 20px;
  }
  :hover {
    ${style.css.lightShadow};
  }
`;
