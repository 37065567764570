import React, { useState, useEffect } from "react";
import Context from "../../Context";
import {
  Container,
  Typo,
  Flex,
  Button,
  Grid,
  Badge,
  ContentContainer,
  Input,
} from "../../components";
import {
  getThaiDateAndTimeFormat,
  getThaiDateFormat,
  statusColor,
  statusTranslated,
  statuses,
  validate,
} from "../../constants";
import { IoIosArrowForward } from "react-icons/io";
import styled from "styled-components";
import themes from "../../themes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { monthTh } from "../../util";
import { Collapse, Modal } from "reactstrap";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const Coupon = () => {
  const { callApi, go, configs, profileState, update } = Context();
  const [allCoupon, setAllCoupons] = useState([]);
  const [filteredCoupons, setFilteredCoupons] = useState([]);
  const [isShowMyCoupon, setIsShowMyCoupon] = useState(false);
  const [myCoupon, setMyCoupon] = useState([]);
  const [isShowReserveModal, setIsShowReserveModal] = useState(false);
  const [selectedReserveCoupon, setSelectedReserveCoupon] = useState({});
  const [body, setBody] = useState({
    number: null,
    quantity: 1,
  });

  const [isShowReserveCoup, setIsShowReserveCoup] = useState(false);
  const currentDate = window.Date.now();
  const { param } = useParams();
  const location = useLocation();
  useEffect(() => {
    (async () => {
      await callApi("coupons-validateExpireCouponByUserId");

      await Promise.all([
        callApi("coupons-getAllCoupons"),
        callApi("coupons-getTransactionByUserId"),
      ]).then(([allCoupons, myCoupon]) => {
        const availableCoupon = allCoupons.filter(
          (coupon) =>
            (coupon.status === statuses.ACTIVE ||
              coupon.status === statuses.HOLD) &&
            coupon.expireAt > currentDate
        );

        const availableMyCoupon = myCoupon.data?.filter(
          (coup) => coup.status !== statuses.CANCEL
        );

        if (param === "my_coupon") {
          setIsShowMyCoupon(true);
        }

        if (param === "reserve_coupon") {
          setIsShowReserveCoup(true);
          setFilteredCoupons(
            availableCoupon.filter((data) => data.type === "reserve")
          );
        } else {
          setFilteredCoupons(
            availableCoupon.filter((data) => data.type === "voucher")
          );
        }

        setAllCoupons(availableCoupon);
        setMyCoupon(availableMyCoupon);
      });
    })();
  }, []);

  const handleSelectMyCoupon = async (order) => {
    if (order.coupon.status === statuses.ACTIVE) {
      if (order.coupon.type === "reserve") {
        setIsShowReserveModal((prevState) => !prevState);
        setSelectedReserveCoupon(order);
      } else {
        localStorage.setItem("selectedCouponId", order.id);
        toast(`เลือกใช้คูปอง ${order.coupon.name}`);
        go("/search");
      }
    }
  };

  const toggle = () => {
    setIsShowReserveModal((prevState) => !prevState);
    setSelectedReserveCoupon({});
  };

  const onSubmitReserve = async (body) => {
    if (
      selectedReserveCoupon.coupon.quantity >
      selectedReserveCoupon.coupon.reserveOrder.length
    ) {
      const result = await callApi("coupons-addReserveOrder", {
        id: selectedReserveCoupon.id,
        number: body.number,
        quantity: body.quantity,
      });

      if (result) {
        setSelectedReserveCoupon((prevState) => ({
          ...prevState,
          coupon: result.updatedCoupon,
        }));
        setBody({ number: null, quantity: 1 });
      }
    }
  };
  return (
    <Container>
      <ToastContainer />
      <Flex align="center" wrap="wrap" width="100%" margin="0 0 25px 0">
        <Typo.Link1
          onClick={() => go("/")}
          color="navy"
          cursor="pointer"
          decoration="none"
        >
          หน้าแรก
        </Typo.Link1>
        <Typo.Body6 color="textBlack" margin="0 7px">
          <IoIosArrowForward />
        </Typo.Body6>
        <Typo.Body6 color="gray5">คูปอง</Typo.Body6>
      </Flex>
      <Typo.H6 color="navy" textAlign="center">
        คูปอง
      </Typo.H6>

      <br />
      <Flex
        justify="center"
        width="100%"
        height="68px"
        background="white"
        radius="60px"
        padding="8px"
      >
        <Button
          grow={1}
          background={!isShowMyCoupon && "yellow"}
          hoverBg={isShowMyCoupon && "white"}
          onClick={() => setIsShowMyCoupon(false)}
          radius="60px"
        >
          <Typo.H6
            color="navy"
            weight="bold"
            hoverColor={isShowMyCoupon && "yellow"}
          >
            คูปองทั้งหมด
          </Typo.H6>
        </Button>
        <Button
          grow={1}
          background={isShowMyCoupon && "yellow"}
          hoverBg={!isShowMyCoupon && "white"}
          onClick={() => setIsShowMyCoupon(true)}
          radius="60px"
        >
          <Typo.H6
            color="navy"
            weight="bold"
            hoverColor={!isShowMyCoupon && "yellow"}
          >
            คูปองของฉัน
          </Typo.H6>
        </Button>
      </Flex>
      <br />
      <ContentContainer
        bgcolor="gradient2"
        minHeight="10px"
        padding="16px 24px"
      >
        <Flex justify="space-between" align="center">
          <Flex direction="column" align="center" gap="8px">
            <Typo.Body2 color="white">คะแนนสะสมของคุณ</Typo.Body2>
            <Typo.Body5>
              ใช้ได้ถึงสิ้นเดือน {monthTh[profileState.pointExpireMonth || 12]}
              {configs.roundDate.split("-")[1] > profileState.pointExpireMonth
                ? " 2568"
                : " 2567"}
            </Typo.Body5>
          </Flex>
          <Flex align="center" gap="10px">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.1593 0.443922C2.8527 1.80168 -3.27818 14.4552 2.65518 24.1873C6.88822 31.1305 15.9265 33.6553 23.16 29.9151C32.5898 25.0394 34.5623 12.4299 27.0676 4.9352C23.71 1.5776 18.8373 -0.117838 14.1593 0.443922ZM17.1762 7.87264C23.7081 8.62368 24.4731 17.6089 18.1593 19.4201C17.6254 19.5732 17.5036 19.5828 15.7686 19.6087L13.9405 19.636V21.9116V24.1873H11.7217H9.50302V15.9998V7.81224L13.0812 7.81272C15.0491 7.81296 16.8919 7.83992 17.1762 7.87264ZM13.9405 13.7112V15.7498H15.1258C17.1836 15.7498 17.9047 15.2222 17.9023 13.7185C17.9 12.2583 17.2163 11.7531 15.1749 11.703L13.9405 11.6726V13.7112Z"
                fill="#FFCD00"
              />
            </svg>
            <Typo.H2 color="yellow" margin="5px 0 0 0">
              {new Intl.NumberFormat().format(profileState.buyPoint || 0)}
            </Typo.H2>
          </Flex>
        </Flex>
      </ContentContainer>
      <br />
      <br />
      {!isShowMyCoupon && (
        <Flex width="100%" borderBottom={`3px solid ${themes.colors.gray2}`}>
          <Typo.H6
            width="50%"
            color={isShowReserveCoup ? "gray" : "navy"}
            textAlign="center"
            padding="0 0 10px 0"
            borderBottom={
              isShowReserveCoup ? "" : `10px solid ${themes.colors.yellow}`
            }
            onClick={() => {
              setIsShowReserveCoup((prevState) => !prevState);

              setFilteredCoupons(
                allCoupon.filter((data) => data.type === "voucher")
              );
            }}
            cursor="pointer"
          >
            คูปองสลาก
          </Typo.H6>
          <Typo.H6
            width="50%"
            color={isShowReserveCoup ? "navy" : "gray"}
            textAlign="center"
            borderBottom={
              isShowReserveCoup ? `10px solid ${themes.colors.yellow}` : ""
            }
            onClick={() => {
              setIsShowReserveCoup((prevState) => !prevState);
              setFilteredCoupons(
                allCoupon.filter((data) => data.type === "reserve")
              );
            }}
            cursor="pointer"
          >
            คูปองจองสิทธิ์เลขท้าย
          </Typo.H6>
        </Flex>
      )}

      <Flex margin="25px 0" justify="space-between" wrap="wrap">
        <Typo.Body2 color="textBlack">
          {isShowMyCoupon ? "คูปองของฉัน" : "คูปองทั้งหมด"}
        </Typo.Body2>
        <div className="flex">
          <Typo.Link1
            color="navy"
            cursor="pointer"
            onClick={() => go("/coupon/history")}
          >
            ประวัติการสั่งซื้อ
          </Typo.Link1>
          <IoIosArrowForward />
        </div>
      </Flex>

      {isShowMyCoupon ? (
        <MyCoupon
          myCoupon={myCoupon}
          go={go}
          handleSelectMyCoupon={handleSelectMyCoupon}
          isShowReserveModal={isShowReserveModal}
          toggle={toggle}
          selectedReserveCoupon={selectedReserveCoupon}
          body={body}
          setBody={setBody}
          onSubmitReserve={onSubmitReserve}
        />
      ) : (
        <AllCoupon
          allCoupon={filteredCoupons}
          go={go}
          configs={configs}
          profileState={profileState}
        />
      )}
    </Container>
  );
};

const MyCoupon = ({
  myCoupon,
  go,
  handleSelectMyCoupon,
  isShowReserveModal,
  toggle,
  selectedReserveCoupon,
  body,
  setBody,
  onSubmitReserve,
}) => {
  const [isExpired, setIsExpired] = useState(false);

  if (myCoupon.length === 0) {
    return (
      <Flex justify="center">
        <Typo.H6 margin="50px 0" color="textBlack">
          ไม่พบคูปอง
        </Typo.H6>
      </Flex>
    );
  }
  return (
    <>
      <ReserveModal
        selectedReserveCoupon={selectedReserveCoupon}
        toggle={toggle}
        isShowReserveModal={isShowReserveModal}
        body={body}
        setBody={setBody}
        onSubmitReserve={onSubmitReserve}
        go={go}
      />
      <Flex width="100%" borderBottom={`3px solid ${themes.colors.gray2}`}>
        <Typo.H6
          width="50%"
          color={isExpired ? "gray" : "navy"}
          textAlign="center"
          padding="0 0 10px 0"
          borderBottom={isExpired ? "" : `10px solid ${themes.colors.yellow}`}
          onClick={() => setIsExpired((prevState) => !prevState)}
          cursor="pointer"
        >
          คูปองที่ใช้ได้
        </Typo.H6>
        <Typo.H6
          width="50%"
          color={isExpired ? "navy" : "gray"}
          textAlign="center"
          borderBottom={isExpired ? `10px solid ${themes.colors.yellow}` : ""}
          onClick={() => setIsExpired((prevState) => !prevState)}
          cursor="pointer"
        >
          ใช้แล้ว / หมดอายุ
        </Typo.H6>
      </Flex>
      <br />
      <Collapse isOpen={!isExpired}>
        <Grid
          className="fix-height"
          columns={2}
          columnsTablet={2}
          columnsIpad={2}
          columnsMobile={1}
          width="100%"
          justify="center"
          align="flex-start"
          gap="15px"
          overflow="hidden"
        >
          {myCoupon
            .filter((order) => order.coupon.status === "active")
            .sort((a, b) => a.coupon.expireAt - b.coupon.expireAt)
            .map((coup) => {
              return (
                <CouponContainer
                  key={coup.id}
                  disabled={coup.coupon.status === statuses.EXPIRED}
                  onClick={() => handleSelectMyCoupon(coup)}
                >
                  {coup.coupon.previewImage ? (
                    <ImageContainer imageUrl={coup.coupon.previewImage} />
                  ) : (
                    <Flex
                      direction="column"
                      width="45%"
                      background={
                        coup.coupon.status === statuses.EXPIRED
                          ? "gray2"
                          : "fadeGreen"
                      }
                      border={`2px solid ${
                        coup.coupon.status === statuses.EXPIRED
                          ? themes.colors.gray4
                          : themes.colors.green
                      }`}
                      height="100%"
                      justify="center"
                      align="center"
                    >
                      <Typo.Body3
                        color={
                          coup.coupon.status === statuses.EXPIRED
                            ? "gray4"
                            : "green"
                        }
                      >
                        {coup.coupon.type === "discount"
                          ? "ส่วนลดซื้อลอตเตอรี่"
                          : coup.coupon.type === "reserve"
                          ? "จองเลขท้าย"
                          : `HT-Coupon`}
                      </Typo.Body3>

                      <Flex margin="10px 0 0 0" align="baseline" gap="10px">
                        <Typo.H3
                          color={
                            coup.coupon.status === statuses.EXPIRED
                              ? "gray4"
                              : "green"
                          }
                        >
                          {coup.coupon.type === "discount"
                            ? ` ${new Intl.NumberFormat().format(
                                coup.coupon.discount
                              )}฿`
                            : coup.coupon.type === "reserve"
                            ? `${new Intl.NumberFormat().format(
                                coup.coupon.quantity
                              )} สิทธ์`
                            : `${new Intl.NumberFormat().format(
                                coup.coupon.quantity
                              )} ใบ`}
                        </Typo.H3>
                        {/* <Typo.Body5 color={isSelected ? "green" : "gray4"}>
              {data.minimum ? `ซื้อขั้นต่ำ ${data.minimum} ใบ` : "ไม่มีขั้นต่ำ"}
            </Typo.Body5> */}
                      </Flex>
                    </Flex>
                  )}
                  <Flex
                    justify="space-between"
                    minHeight="150px"
                    padding="15px 50px 15px 15px"
                    direction="column"
                  >
                    <div>
                      <Typo.Body4
                        color={
                          coup.coupon.status === statuses.EXPIRED
                            ? "gray4"
                            : "navy"
                        }
                      >
                        {coup.coupon.name}
                      </Typo.Body4>

                      {coup.coupon.type === "reserve" ? (
                        <Badge
                          width="100%"
                          background={statusColor[coup?.coupon?.status]}
                        >
                          {coup?.coupon?.quantity ===
                          (coup?.coupon?.reserveOrder || []).length
                            ? "ใช้สิทธิ์ครบจำนวนแล้ว"
                            : `${
                                statusTranslated[coup?.coupon?.status] +
                                " " +
                                (coup?.coupon?.quantity -
                                  (coup?.coupon?.reserveOrder || []).length)
                              } / ${coup?.coupon?.quantity}`}
                        </Badge>
                      ) : (
                        <Badge
                          width="100%"
                          background={statusColor[coup?.coupon?.status]}
                        >
                          {statusTranslated[coup?.coupon?.status]}
                        </Badge>
                      )}
                      {coup?.coupon?.usedBy && (
                        <Typo.Body8 margin="5px 0" color="textBlack">
                          หมายเลขออเดอร์อ้างอิง {coup?.coupon?.usedBy}
                        </Typo.Body8>
                      )}
                    </div>
                    <Typo.Body8 margin="5px 0" color="gray4">
                      หมดอายุ{" "}
                      {getThaiDateAndTimeFormat(
                        new window.Date(coup.coupon?.expireAtDate)
                      )}
                    </Typo.Body8>
                  </Flex>
                </CouponContainer>
              );
            })}
        </Grid>
      </Collapse>
      <Collapse isOpen={isExpired}>
        <Grid
          className="fix-height"
          columns={2}
          columnsTablet={2}
          columnsIpad={2}
          columnsMobile={1}
          width="100%"
          justify="center"
          align="flex-start"
          gap="15px"
          overflow="hidden"
        >
          {myCoupon
            .filter((order) => order.coupon.status !== "active")
            .sort((a, b) => a.coupon.expireAt - b.coupon.expireAt)
            .map((coup) => {
              return (
                <CouponContainer
                  key={coup.id}
                  disabled={coup.coupon.status === statuses.EXPIRED}
                >
                  {coup.coupon.image ? (
                    <ImageContainer imageUrl={coup.coupon.image} />
                  ) : (
                    <Flex
                      direction="column"
                      width="45%"
                      background={
                        coup.coupon.status === statuses.EXPIRED
                          ? "gray2"
                          : "fadeGreen"
                      }
                      border={`2px solid ${
                        coup.coupon.status === statuses.EXPIRED
                          ? themes.colors.gray4
                          : themes.colors.green
                      }`}
                      height="100%"
                      justify="center"
                      align="center"
                    >
                      <Typo.Body3
                        color={
                          coup.coupon.status === statuses.EXPIRED
                            ? "gray4"
                            : "green"
                        }
                      >
                        {coup.coupon.type === "discount"
                          ? "ส่วนลดซื้อลอตเตอรี่"
                          : coup.coupon.type === "reserve"
                          ? "จองเลขท้าย"
                          : `HT-Coupon`}
                      </Typo.Body3>

                      <Flex margin="10px 0 0 0" align="baseline" gap="10px">
                        <Typo.H3
                          color={
                            coup.coupon.status === statuses.EXPIRED
                              ? "gray4"
                              : "green"
                          }
                        >
                          {coup.coupon.type === "discount"
                            ? ` ${new Intl.NumberFormat().format(
                                coup.coupon.discount
                              )}฿`
                            : coup.coupon.type === "reserve"
                            ? `${new Intl.NumberFormat().format(
                                coup.coupon.quantity
                              )} สิทธิ์`
                            : `${new Intl.NumberFormat().format(
                                coup.coupon.quantity
                              )} ใบ`}
                        </Typo.H3>
                        {/* <Typo.Body5 color={isSelected ? "green" : "gray4"}>
            {data.minimum ? `ซื้อขั้นต่ำ ${data.minimum} ใบ` : "ไม่มีขั้นต่ำ"}
          </Typo.Body5> */}
                      </Flex>
                    </Flex>
                  )}
                  <Flex
                    justify="space-between"
                    minHeight="150px"
                    padding="15px 50px 15px 15px"
                    direction="column"
                  >
                    <div>
                      <Typo.Body4
                        color={
                          coup.coupon.status === statuses.EXPIRED
                            ? "gray4"
                            : "navy"
                        }
                      >
                        {coup.coupon.name}
                      </Typo.Body4>

                      <Badge
                        width="100%"
                        background={statusColor[coup?.coupon?.status]}
                      >
                        {statusTranslated[coup?.coupon?.status]}
                      </Badge>
                      {coup?.coupon?.usedBy && (
                        <Typo.Body8 margin="5px 0" color="textBlack">
                          หมายเลขออเดอร์อ้างอิง {coup?.coupon?.usedBy}
                        </Typo.Body8>
                      )}
                    </div>
                    <Typo.Body8 margin="5px 0" color="gray4">
                      หมดอายุ{" "}
                      {getThaiDateAndTimeFormat(
                        new window.Date(coup.coupon?.expireAtDate)
                      )}
                    </Typo.Body8>
                  </Flex>
                </CouponContainer>
              );
            })}
        </Grid>
      </Collapse>
    </>
  );
};

const ReserveModal = ({
  isShowReserveModal,
  toggle,
  selectedReserveCoupon,
  body,
  setBody,
  onSubmitReserve,
  go,
}) => {
  if (!selectedReserveCoupon) return;
  const { coupon, shortId } = selectedReserveCoupon;

  return (
    <Modal centered isOpen={isShowReserveModal} toggle={toggle}>
      <ContentContainer
        minHeight="0"
        bgcolor="navy"
        margin="0"
        padding="25px 15px"
      >
        <Flex direction="column" gap="20px">
          <Typo.H4>{coupon?.name}</Typo.H4>
          <Flex gap="10px">
            <Typo.Body1>ออเดอร์ไอดี:</Typo.Body1>
            <Typo.Body1>{shortId} </Typo.Body1>
          </Flex>
          <Flex gap="10px" align="center">
            <Typo.Body1>สถานะคูปอง:</Typo.Body1>
            <Badge background={statusColor[coupon?.status]}>
              {statusTranslated[coupon?.status]}
            </Badge>
          </Flex>
          <Flex gap="10px">
            <Typo.Body1>จำนวนสิทธิ์คงเหลือ:</Typo.Body1>
            <Typo.Body1>
              {coupon?.quantity - (coupon?.reserveOrder || []).length}/{" "}
              {coupon?.quantity}
            </Typo.Body1>
          </Flex>

          {coupon?.reserveOrder?.map((order, i) => {
            return (
              <ContentContainer padding="15px" gap="5px" minHeight="0">
                <Flex>
                  <Flex direction="column" align="center" grow={1}>
                    <Typo.Body1 color="black" margin="5px 0">
                      เลขท้าย
                    </Typo.Body1>
                    <Typo.H6 color="black" margin="5px 0">
                      {order?.number}
                    </Typo.H6>
                  </Flex>
                  <Flex direction="column" align="center" grow={1}>
                    <Typo.Body1 color="black" margin="5px 0">
                      จำนวน
                    </Typo.Body1>
                    <Typo.H6 color="black" margin="5px 0">
                      {order?.quantity}
                    </Typo.H6>
                  </Flex>
                  <Flex direction="column" align="center" grow={1}>
                    <Typo.Body1 color="black" margin="5px 0">
                      สถานะ
                    </Typo.Body1>
                    <Badge
                      cursor={order.status !== statuses.PENDING && "pointer"}
                      background={
                        statusColor[order.status] === statuses.PENDING
                          ? "orange"
                          : "green"
                      }
                      onClick={() => go("/orders")}
                    >
                      {order.status === statuses.PENDING
                        ? "รอดำเนินการ"
                        : order.orderId}
                    </Badge>
                  </Flex>
                </Flex>
              </ContentContainer>
            );
          })}
          <br />
          {selectedReserveCoupon?.coupon?.quantity >
            (selectedReserveCoupon?.coupon?.reserveOrder.length || 0) && (
            <>
              <Flex>
                <div style={{ flexGrow: 1 }}>
                  <Typo.Body1 margin="5px 0">เลขท้ายที่ต้องการจอง</Typo.Body1>
                  <Input
                    className="text-center "
                    type="number"
                    width="95px"
                    value={body.number}
                    placeholder="xx"
                    onChange={(e) => {
                      if (e.target.value.length <= 2) {
                        setBody((prevState) => ({
                          ...prevState,
                          number: e.target.value,
                        }));
                      }
                    }}
                  />
                </div>
                <div style={{ flexGrow: 1 }}>
                  <Typo.Body1 margin="5px 0">จำนวนใบที่ต้องการจอง</Typo.Body1>
                  <Input
                    className="text-center "
                    type="number"
                    width="95px"
                    value={body.quantity}
                    onChange={(e) => {
                      if (e.target.value <= 50) {
                        setBody((prevState) => ({
                          ...prevState,
                          quantity: e.target.value,
                        }));
                      }
                    }}
                  />
                  <Typo.Body6 color="dangerRed" margin="15px 0">
                    *จองได้สูงสุด 50 ใบ/ครั้ง
                  </Typo.Body6>
                </div>
              </Flex>
              <Button
                disabled={body.number?.length !== 2}
                onClick={() => {
                  onSubmitReserve(body);
                }}
                hoverBg="success"
                background="yellow"
              >
                จองเลขท้าย {body.number || "xx"} จำนวน {body.quantity} ใบ
              </Button>
            </>
          )}
        </Flex>
      </ContentContainer>
    </Modal>
  );
};

const AllCoupon = ({ allCoupon, go, configs, profileState }) => {
  if (allCoupon.length === 0 || !configs.isOpen?.coupon) {
    return (
      <img
        src="/images/closeCouponBackground.png"
        alt="close"
        style={{ width: "100%" }}
      />
    );
  }

  return (
    <Grid
      className="fix-height"
      columns={2}
      columnsTablet={2}
      columnsIpad={2}
      columnsMobile={1}
      width="100%"
      justify="center"
      align="flex-start"
      gap="15px"
      overflow="hidden"
    >
      {allCoupon
        .sort((a, b) => a.price - b.price)
        .map((coup) => {
          return (
            <CouponContainer
              key={coup.id}
              disabled={coup.status === statuses.HOLD}
              onClick={() =>
                coup.status === statuses.ACTIVE &&
                go(`/coupon/payment/${coup.id}`)
              }
            >
              {coup.previewImage ? (
                <ImageContainer imageUrl={coup.previewImage} />
              ) : (
                <Flex
                  direction="column"
                  width="45%"
                  background="fadeGreen"
                  border={`2px solid ${themes.colors.green}`}
                  height="100%"
                  justify="center"
                  align="center"
                >
                  <Typo.Body3 color="green">
                    {coup.type === "discount"
                      ? "ส่วนลด"
                      : coup.type === "reserve"
                      ? "จองเลขท้าย"
                      : `HT-Coupon`}
                  </Typo.Body3>

                  <Flex margin="10px 0 0 0" align="baseline" gap="10px">
                    <Typo.H3 color="green">
                      {coup.type === "discount"
                        ? ` ${new Intl.NumberFormat().format(coup.discount)}฿`
                        : coup.type === "reserve"
                        ? `${new Intl.NumberFormat().format(
                            coup.quantity
                          )} สิทธิ์`
                        : `${new Intl.NumberFormat().format(coup.quantity)} ใบ`}
                    </Typo.H3>
                  </Flex>
                </Flex>
              )}
              {/* )} */}
              <Flex
                justify="space-between"
                minHeight="150px"
                // padding="15px"
                direction="column"
                padding="15px 35px 15px 15px"
              >
                <div>
                  <Typo.Body2 color="navy">
                    {coup.isPointRedeem
                      ? `ใช้  ${coup.price.toLocaleString()} คะแนน`
                      : `ราคา ${coup.price.toLocaleString()} บาท`}
                  </Typo.Body2>

                  <Typo.Body4 margin="15px 0 0 0" color="textBlack">
                    {coup.name}
                  </Typo.Body4>
                </div>

                <Typo.Body8 color="gray4">
                  หมดอายุ {getThaiDateFormat(new Date(coup.expireAtDate))}
                </Typo.Body8>
              </Flex>
            </CouponContainer>
          );
        })}
    </Grid>
  );
};

const ImageContainer = styled.div`
  background: url(${(props) => props.imageUrl});
  width: 45%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  border-right: 3px dashed ${themes.colors.gray4};
`;

const CouponContainer = styled.div`
  position: relative;
  margin: 0 2em;
  background: radial-gradient(
    circle at right center,
    transparent,
    transparent 30px,
    white 30px,
    white
  );
  /* background: radial-gradient(25px at right, #0000 100%, #fff) right;
  background-size: 50.5% 100%;
  background-repeat: repeat-y;
  min-height: 100px; */
  height: 100%;
  width: 100%;
  border: 0;

  border-radius: 0.5rem;
  overflow: hidden;
  cursor: ${(props) => (props.disabled ? "no-drop" : "pointer")};
  display: flex;
  gap: 5px;
`;
